import React from "react"
import PartsDiagramViewer from "@/components/PartsViewer/v2/PartsDiagramViewer"
import DetailPopUp from "@/components/PartsViewer/v2/DetailPopUp/"
import GridAndListPanel from "@/components/PartsViewer/v2/GridAndListPanel"
import { LoaderSpinner } from "@/components/PartsViewer/v2/icon"
import ErrorFallbackUI from "@/components/PartsViewer/v2/ErrorFallBack"

const DesktopView = props => {
  const {
    selectedFile,
    fullscreen,
    expandHandler,
    partsList,
    setIsListExtend,
    isListExtend,
    title,
    showPopup,
    popupModal,
    setModal,
    productId,
    partController,
    sku,
    loading,
    analytics,
  } = props

  if (loading.error) return <ErrorFallbackUI />
  return (
    <>
      <div className={`${isListExtend ? "" : "fullscreen-extend"} left`}>
        {!loading?.status ? (
          <PartsDiagramViewer
            expandHandler={expandHandler}
            fullscreen={fullscreen}
            showPopup={showPopup}
            selectedFile={selectedFile}
            partController={partController}
            sku={sku}
            title={title}
          />
        ) : (
          <LoaderSpinner />
        )}
      </div>
      {!loading?.error ? (
        <div
          className={`${
            isListExtend ? "fullscreen-extend" : ""
          } fullscreen-right h-100`}
        >
          <GridAndListPanel
            partsList={partsList}
            setIsListExtend={setIsListExtend}
            isListExtend={isListExtend}
            title={title}
            showPopup={showPopup}
            partController={partController}
            sku={sku}
          />
        </div>
      ) : (
        ""
      )}
      {popupModal ? (
        <DetailPopUp
          productId={productId}
          setModal={setModal}
          partController={partController}
          sku={sku}
          title={title}
          analytics={analytics}
        />
      ) : (
        ""
      )}
    </>
  )
}

export default DesktopView
