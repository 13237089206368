import React, { useState } from "react"
import useViewport from "@/hooks/useViewport"
import ErrorBoundary from "@/components/PartsViewer/v2/ErrorBoundary"
import ErrorFallbackUI from "@/components/PartsViewer/v2/ErrorFallBack"
import FileSelection from "@/components/PartsViewer/v2/SelectedFileView/FileSelection"
import SelectedFileView from "@/components/PartsViewer/v2/SelectedFileView"

const PartsViewerModal = props => {
  const {
    files,
    fileMap,
    title,
    sku,
    skuImage,
    prefeedbackController,
    setParts,
    selectedFile,
    loading,
    analytics = { page: "na" },
    setPartsHeight,
  } = props
  const [selected, setSelected] = useState(selectedFile)
  const [selectedPart, setSelectedPart] = useState("")
  const [isListExtend, setIsListExtend] = useState(false)
  const [panelState, setPanelState] = useState(false)
  const device = useViewport()
  const [popupModal, setPopupModal] = useState(false)
  const [tabsActive, setTabsActive] = useState("")

  let initialIndex
  const isActive = item => item?.FileName == selected?.FileName

  for (let i = 0; i <= files?.length - 1; i++) {
    if (files[i]?.FileName === selected?.FileName) {
      initialIndex = i
    }
  }

  const getFileTitle = name =>
    fileMap?.[name] === undefined ? name?.replace(/.svg/i, "") : fileMap?.[name]

  const showPopup = (selectedItem, popupFlag = true) => {
    const getSelectedItems = selected?.Parts.filter(
      val => val?.Cod === selectedItem
    )
    if (getSelectedItems.length !== 0) {
      setPopupModal(popupFlag)
      setSelectedPart(selectedItem)
    } else {
      setPopupModal(false)
    }
  }
  return (
    <>
      {!loading?.error ? (
        <div
          className={`modal-fullscreen modal-container ${
            device.isSmallScreen ? "mobile-viewer" : ""
          }  ${panelState ? `fullscreen-viewer` : ""}`}
        >
          <FileSelection
            files={files}
            getFileTitle={getFileTitle}
            isActive={isActive}
            setSelected={setSelected}
            setPopupModal={setPopupModal}
            title={title}
            sku={sku}
            skuImage={skuImage}
            setParts={setParts}
            panelState={panelState}
            initialIndex={initialIndex}
            setPartsHeight={setPartsHeight}
          />
        </div>
      ) : (
        ""
      )}

      <div
        className={`parts-modal-wrapper modal_content ${
          device.isSmallScreen ? "align-items-start" : ""
        }  ${panelState ? `fullscreen-viewer` : ""}`}
      >
        <ErrorBoundary
          key={selected?.FileName}
          FallBackComponent={ErrorFallbackUI}
          params={{ title: getFileTitle(selected?.FileName) }}
        >
          <SelectedFileView
            expandHandler={() => setPanelState(state => !state)}
            panelState={panelState}
            showPopup={showPopup}
            selectedFile={selected}
            partsList={selected?.Parts}
            setIsListExtend={setIsListExtend}
            isListExtend={isListExtend}
            title={getFileTitle(selected?.FileName)}
            productId={selectedPart}
            tabsActive={tabsActive}
            setModal={setPopupModal}
            popupModal={popupModal}
            selected={selected}
            onClose={setTabsActive}
            sku={title?.props?.sku}
            prefeedbackController={prefeedbackController}
            loading={loading}
            analytics={analytics}
          />
        </ErrorBoundary>
      </div>
      {/* </div> */}
    </>
  )
}

export default PartsViewerModal
