export const getAnalyticsData = (
  type,
  url,
  needsJSON = false,
  isBundleProduct = false
) => {
  const title = type.toLowerCase().trim()
  let eventAction
  let internalLinkZoneName
  let internalLinkURL
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  if (title === "notify me") {
    internalLinkZoneName = `${page?.category?.pageType}:modal:availability notification`
    eventAction = `${internalLinkZoneName}:${title}`
  } else if (title === "local store or showroom") {
    eventAction = `${page?.category?.pageType}:product details:add-on services:${title}`
    internalLinkURL = `${url.toLowerCase().trim()}`
  } else if (title === "where to buy") {
    internalLinkURL = `${window.location.origin + url}`
  }
  const eventInfo = {
    eventAction:
      eventAction || `${page?.category?.pageType}:product details:${title}`,
    eventName:
      eventAction || `${page?.category?.pageType}:product details:${title}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: `${title}`,
    internalLinkPosition: `${page?.category?.pageType}${
      isBundleProduct ? ":bundle" : ""
    }`,
    internalLinkType: `${page?.category?.pageType}:${title}`,
    internalLinkZoneName:
      internalLinkZoneName || `${page?.category?.pageType}:product details`,
    internalLinkURL: internalLinkURL || "n/a",
    clickInternalLinks: "true",
  }
  if (needsJSON) {
    return eventInfo
  }
  return JSON.stringify(eventInfo)
}

export const replaceAddToCartDatalayerPush = (
  replaceAddToCartCtaLabel,
  replaceAddToCartCtaLink,
  analyticData
) => {
  const eventInfo = getAnalyticsData(
    replaceAddToCartCtaLabel,
    replaceAddToCartCtaLink,
    true
  )
  const page = window.adobeDataLayer.getState("page")
  analyticData = JSON.parse(analyticData)
  const productInfo = analyticData?.productInfo
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    productInfo,
    page,
  })
}

export const addToCompareAnalytics = (isChecked, analyticData) => {
  const compareText = `${isChecked ? "remove" : "add to"} compare`
  const eventInfo = {
    eventAction: `pdp:product details:${compareText}`,
    eventName: `pdp:product details:${compareText}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: `${isChecked ? "remove compare" : "compare add"}`,
    internalLinkPosition: "pdp",
    internalLinkType: `pdp:${compareText}`,
    internalLinkZoneName: "pdp:product details",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }

  const page = window.adobeDataLayer.getState("page")
  analyticData = JSON.parse(analyticData)
  const productInfo = analyticData?.productInfo
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    productInfo,
    page,
  })
}
