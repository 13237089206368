import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import Heading from "@/components/core/Heading/Heading"
import useViewport, { useViewportWidth } from "@/hooks/useViewport"
import SwatchItems from "@/components/PartsViewer/v2/SwatchItems"
import NotifyItems from "@/components/PartsViewer/v2/DetailPopUp/NotifyMe"
import LoadingPopupection from "@/components/PartsViewer/v2/DetailPopUp/Loading"
import { shareaddToCart as addToCartHelper } from "@/components/sharedCart/shareCartHelper"
import { getProductInventory } from "@/components/Shared/UpsellInstallServices/api"
import {
  CloseIcon,
  DownIcon,
  LoadingIcon,
  IncIcon,
  DecIcon,
  WarnIcon,
  CartCloseIcon,
  LoaderSpinner,
} from "@/components/PartsViewer/v2/icon.js"
import comingSoon from "@/public/scout_icon/coming-soon.jpg"
import { partsAddToCartEvent } from "@/components/PartsViewer/v2/analytics.js"
import { useSelector } from "react-redux"

const DetailPopUp = props => {
  const { productId, setModal, partController, analytics, title } = props
  const [loading, setLoading] = useState("loading")
  const [item, setItems] = useState({})
  const [varent, setVarent] = useState(null)
  const [varentList, setVarentList] = useState([])
  const [counter, setCounter] = useState(1)
  const [isloading, setIsLoading] = useState(false)
  const [inventory, setInventory] = useState("")
  const [inventoryWarn, setInventoryWarn] = useState(false)
  const [cartToast, setCartToast] = useState(false)
  const [popupWidth, setPopupWidth] = useState(null)
  const showStockDiscontinue = null
  const device = useViewport()
  const resizeWidth = useViewportWidth()
  const pdpDetails = useSelector(state => state.pdp)

  useEffect(() => {
    const element = document.getElementsByClassName("product-list-viewer")[0]
    const headElement = document.querySelectorAll(
      ".modal-fullscreen.modal-container"
    )[0]
    if (element) {
      const { width } = element.getBoundingClientRect()
      const { height } = headElement.getBoundingClientRect()
      setPopupWidth({ sidebarWidth: width, headHeight: height })
    }
  }, [resizeWidth])

  useEffect(() => {
    getItemDetails()
  }, [productId])

  useEffect(() => {
    let timeId
    if (inventory && counter > inventory) {
      timeId = setTimeout(() => {
        setCounter(inventory)
      }, 1000)
      setInventoryWarn(true)
    }
    return () => clearTimeout(timeId)
  }, [counter])

  useEffect(() => {
    getVarientInfo()
  }, [varent])

  const getVarientInfo = async () => {
    const currentVarent = varentList?.filter(val => val.id === varent)[0]
    if (currentVarent) {
      setIsLoading(true)
      const productQuantity = await getInventoryBySku(currentVarent?.id)
      setIsLoading(false)
      setItems(prevState => {
        const details = prevState?.data?.varientDetails?.filter(
          val => val?.sku === currentVarent?.id
        )[0]
        return {
          ...prevState,
          currentVarent: { ...currentVarent, details },
        }
      })
      setInventory(productQuantity)
      setInventoryWarn(false)
      if (productQuantity && counter > productQuantity) {
        setCounter(productQuantity)
        setInventoryWarn(true)
      }
    }
  }

  const getItemDetails = async () => {
    const entry = partController.actions.get.entryByKey(productId)
    setLoading("loading")
    if (entry?.ctApiPromise) {
      const ctResponse = await entry.ctApiPromise
      if (ctResponse) {
        setIsLoading(true)
        const allSwatch = ctResponse?.data?.colorData
        const currentSwatch = allSwatch[0]?.id
        const productQuantity = await getInventoryBySku(currentSwatch)
        const details = ctResponse?.data?.varientDetails?.filter(
          val => val?.sku === currentSwatch
        )[0]
        setIsLoading(false)
        setInventory(productQuantity)
        setVarentList(allSwatch)
        setVarent(currentSwatch)
        setItems({
          ...ctResponse,
          currentVarent: { ...allSwatch[0], details },
        })
        setLoading("success")
      }
    }
  }

  const incrementCounter = () => {
    setCounter(counter + 1)
  }

  const decrementCounter = () => {
    if (counter > 1) setCounter(counter - 1)
  }
  const handlerCounter = val => {
    if (Number(val) && val >= 1) {
      setCounter(val)
    }
  }

  const handlerCart = productInfo => {
    setIsLoading(true)
    const data = [
      {
        sku: productInfo?.currentVarent?.id,
        quantity: counter,
        isBundleProduct: false,
        isBundle: false,
        brand: "kohler",
      },
    ]

    addToCartHelper(data, "", res => {
      if (res) {
        setCartToast(true)
        setIsLoading(false)
        partsAddToCartEvent(analytics, productInfo)
        setTimeout(() => {
          setCartToast(false)
        }, 3000)
      }
    })
  }

  const getInventoryBySku = async sku => {
    const { data: { results: parentProductInventory = [] } = {} } =
      (await getProductInventory(sku)) ?? {}
    return parentProductInventory.length
      ? parentProductInventory[0]?.availableQuantity
      : 0
  }

  if (popupWidth) {
    if (loading === "loading") {
      return (
        <LoadingPopupection
          setModal={setModal}
          device={device}
          popupWidth={popupWidth}
        />
      )
    }

    return (
      <div
        className="popup-default"
        style={
          popupWidth && !device.isSmallScreen
            ? {
                width: popupWidth?.sidebarWidth + "px",
                top: popupWidth.headHeight + "px",
              }
            : {}
        }
      >
        <div className="popup-wrapper">
          <Container fluid>
            <div className="flex-column content-dialogs">
              <div className="header-fixed">
                <div className="d-flex justify-content-start">
                  <button
                    className="btn closebtns"
                    onClick={() => setModal(false)}
                    data-testid="parts-modal-close"
                  >
                    {device?.isLargeScreen || device.isExtraLargeScreen ? (
                      <CloseIcon />
                    ) : (
                      <DownIcon />
                    )}
                  </button>
                </div>
              </div>

              <div className="main-image mt-0">
                {loading === "loading" ? (
                  <LoaderSpinner />
                ) : (
                  <img
                    src={
                      item?.currentVarent?.imgUrl
                        ? item?.currentVarent?.imgUrl
                        : comingSoon?.src
                    }
                    alt="comming soon"
                  />
                )}
              </div>

              <div className="text-container">
                <div className="flag-container">
                  {item?.currentVarent?.details?.discontinued ? (
                    <p className={`fs-13 fw-600 tag-discontinue discontinued`}>
                      Discontinued
                    </p>
                  ) : null}

                  {(item?.currentVarent?.details?.isStock === "false" ||
                    inventory === 0) &&
                  item?.currentVarent?.details?.discontinued === false ? (
                    <p className={`fs-13 fw-600 tag-discontinue outof-stoke`}>
                      out of stock
                    </p>
                  ) : null}
                </div>

                <Heading
                  level={2}
                  className="label-name fs-28 fw-325 text-capitalize"
                >
                  {item?.Name?.toLowerCase() || "N/A"}
                </Heading>
                <p className="varient-text fs-14 fw-500">
                  {item?.currentVarent?.value || "N/A"}
                </p>
                <Heading
                  level={2}
                  className="price-text fs-28 fw-325 text-capitalize"
                >
                  {item?.currentVarent?.currencySign}
                  {item?.currentVarent?.price}
                </Heading>
                <p className="pricelist-text fs-16 fw-400 product-description mb-2">
                  {item?.currentVarent?.currencySign}
                  {item?.currentVarent?.pricelist}{" "}
                  <span className="list-text">
                    <span> List Price</span>

                    <span className="list-description">
                      Selling price is valid for <b>Kohler.com</b> and United
                      States only. Store and distribution pricing may differ.
                      These prices do not include potential shipping costs. Any
                      sales tax applicable will be added to the price.
                    </span>
                  </span>
                </p>
                <p className="fs-16 fw-300 text-color">
                  <span className="fw-400">Color/Finish </span>
                  {item?.currentVarent?.label || "N/A"}
                </p>
                <SwatchItems
                  varentList={varentList}
                  setVarent={setVarent}
                  varent={varent}
                />

                <div className="d-flex align-items-center justify-content-center detailpop-up">
                  {showStockDiscontinue != null ? (
                    <button
                      className="fw-400 stock-button mt-5"
                      disabled={true}
                    >
                      {showStockDiscontinue}
                    </button>
                  ) : (
                    <div className="button-carts d-flex flex-row justify-content-center align-items-end mt-3">
                      <div
                        className={`d-flex flex-column processing-div ${
                          isloading ? "processing" : ""
                        }`}
                      >
                        {inventoryWarn ? (
                          <div className="inventory-warning">
                            <WarnIcon /> {inventory} item(s) available to ship.
                          </div>
                        ) : (
                          ""
                        )}
                        {!item?.currentVarent?.details?.discontinued &&
                        loading === "success" &&
                        inventory ? (
                          <div className="addtocart-widgets d-flex">
                            <div className="counter-widgets d-flex">
                              <button
                                className="decrement-widgets"
                                onClick={decrementCounter}
                              >
                                <DecIcon />
                              </button>
                              <input
                                type="number"
                                onChange={e => handlerCounter(e?.target?.value)}
                                value={counter}
                              />
                              <button
                                className="increment-widgets"
                                onClick={incrementCounter}
                              >
                                <IncIcon />
                              </button>
                            </div>
                            <div className="button-carts">
                              <button
                                onClick={() =>
                                  !isloading
                                    ? handlerCart({
                                        ...item,
                                        fileTitle: title,
                                        quantity: counter,
                                        pdpDetails,
                                      })
                                    : null
                                }
                                disabled={isloading}
                                type="button"
                                className={`fs-18 fw-500 add-to-cart d-flex align-items-center justify-content-center ${
                                  isloading ? "disabled" : ""
                                }
                        `}
                              >
                                {isloading ? <LoadingIcon /> : "Add to Cart"}
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {(item?.currentVarent?.details?.isStock === "false" ||
                          inventory === 0) &&
                        !item?.currentVarent?.details?.discontinued ? (
                          <NotifyItems item={item} />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>
        {cartToast ? (
          <div className="cart-toast-text">
            <p>
              ( {counter} ) {counter > 1 ? "Items" : "Item"} has been added to
              your cart.
            </p>
            <button onClick={() => setCartToast(false)}>
              <CartCloseIcon />
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    )
  }
}

export default DetailPopUp
