import { useEffect, useCallback, useState, useRef } from "react"
import { staticClasses } from "@/constants"
import { isMobile } from "react-device-detect"
import styles from "@/components/Shared/HeaderNavTabs/index.module.scss"
import {
  getPureText,
  preventBodyScrollTab,
  pushAnalyticsObjToDataLayer,
} from "@/utils/helper"
import DOMPurify from "isomorphic-dompurify"
import { addAnalyticsOnClose } from "@/components/Shared/HeaderNavTabs/analytics"
import SmartCropImage from "@/components/core/SmartCropImage"
import useSmartCrop from "@/hooks/useSmartCrop"
import { DATA_LAYER_REGEX, PURE_DATA_FOR_STRING } from "@/constants/regex"

const HeaderNavTabsV3 = props => {
  const {
    data,
    columnClassNames = "",
    eventHandler: {
      store: { isOpen },
      callbacks: {
        // eslint-disable-next-line no-console
        toggleMenu = () => console.log("Event Not Defined"),
        // eslint-disable-next-line no-console
        setMainCategory = () => console.log("Event Not Defined"),
      },
    },
  } = props
  const caretimg = "/icons/bold.svg"
  const externalicon = "/icons/externalicon.svg"
  const caretimgWhite = "/icons/bold-white.svg"
  const externalIconWhite = "/icons/external link.svg"
  const [navOpen, setNavOpen] = useState("")
  const smartCrop = useSmartCrop()
  const [selectedTab, setTab] = useState("")
  const [darkThemeFlag, setDarkThemeFlag] = useState(false)

  const menuVisible = useRef(0)
  const dataRecursion = useCallback(data => {
    if (data && data[":itemsOrder"]) {
      data[":itemsOrder"].map((key, index) => {
        const item = data[":items"][key]
        return dataRecursion(item)
      })
    }
  }, [])

  const onClickNav = data => {
    if (data) {
      if (selectedTab !== data["cq:panelTitle"]) {
        setTab(data["cq:panelTitle"])
      }

      if (navOpen !== data["cq:panelTitle"]) {
        setNavOpen(data["cq:panelTitle"])
        if (!isMobile) {
          setNavOpen(data["cq:panelTitle"])
        }
        setMainCategory({ item: data["cq:panelTitle"] })
        menuVisible.current = 1
        preventBodyScrollTab(true)
        // addHeaderNavTabAnalytics(data)
      }
    }
  }
  const handleMouseLeave = () => {
    setNavOpen("")
    setTab("")
    setMainCategory({ item: "" })
    menuVisible.current = 0
    preventBodyScrollTab(false)
  }
  // keeping this for mobile data layer
  // const addHeaderNavTabAnalytics = data => {
  //   const eventInfo = {
  //     eventAction: `global header:${data["cq:panelTitle"]?.toLowerCase()}`,
  //     eventName: `global header:${data["cq:panelTitle"]?.toLowerCase()}`,
  //     eventType: "tab",
  //     internalLinkName: data["cq:panelTitle"]?.toLowerCase(),
  //     internalLinkPosition: "global header",
  //     internalLinkType: "global navigation",
  //     internalLinkZoneName: "global header",
  //     internalLinkURL: "n/a",
  //     clickInternalLinks: "true",
  //   }
  //   pushAnalyticsObjToDataLayer(eventInfo, "cmp:click")
  // }

  const servicesClick = data => {
    const { text = "", link = "", Leveltwo = "", L1 = "", imgcheck = "" } = data
    const textcont = getPureText(text)
      ?.toLowerCase()
      ?.replace(DATA_LAYER_REGEX, "&")
    const l2DatalayerReady = getPureText(Leveltwo)
      ?.toLowerCase()
      ?.replace(DATA_LAYER_REGEX, "&")
    const l1DatalayerReady = getPureText(L1)
      ?.replace(DATA_LAYER_REGEX, "&")
      ?.toLowerCase()

    let internallink
    if (L1) {
      internallink = `${l1DatalayerReady} > ${l2DatalayerReady} > ${textcont}`
    } else if (imgcheck) {
      internallink = `${l2DatalayerReady} > img_${textcont}`
    } else {
      internallink = `${l2DatalayerReady} > ${textcont}`
    }

    const eventInfo = {
      clickInternalLinks: "true",
      eventAction: "main dropdown menu",
      eventName: "main dropdown menu",
      eventType: "global navigation",
      internalLinkName: getPureText(internallink)?.replace(
        PURE_DATA_FOR_STRING,
        ""
      ),
      internalLinkPosition: "global header",
      internalLinkType: "global header:navigation",
      internalLinkURL: link ? link : "n/a",
      internalLinkZoneName: "global header",
    }
    pushAnalyticsObjToDataLayer(eventInfo, "cmp:click")
  }

  const onMenuClickEvent = e => {
    if (
      (e?.target?.classList?.contains("gbh-pro-menu__inner") ||
        (e?.target?.closest(".gbh-pro-menu__inner") === null &&
          e?.target?.closest(".gbh-full-w-menu__inner") === null &&
          e?.target?.closest(".cmp-tabs__tablist") === null &&
          !isMobile)) &&
      menuVisible.current
    ) {
      setNavOpen("")
      setTab("")
      setMainCategory({ item: "" })
      menuVisible.current = 0
      preventBodyScrollTab(false)
    }
  }
  useEffect(() => {
    if (
      document
        ?.getElementsByTagName?.("body")?.[0]
        ?.className?.includes("nav-dark-theme")
    ) {
      setDarkThemeFlag(true)
    }
  }, [])

  useEffect(() => {
    if (navOpen) {
      document.addEventListener("click", onMenuClickEvent)
    } else {
      document.removeEventListener("click", onMenuClickEvent)
    }
    return () => document.removeEventListener("click", onMenuClickEvent)
  }, [navOpen])

  useEffect(() => {
    dataRecursion(data)
  }, [data, dataRecursion])

  const getInternalLinkURLS = url => {
    if (typeof window !== "undefined") {
      return url?.startsWith("/") ||
        url?.includes(window.location.hostname) ||
        url === null
        ? false
        : true
    }
  }
  return (
    <div id="HEADER ID" className={styles.headerNavV3Wrapper}>
      <div
        className={`tabs ${
          data?.appliedCssClassNames
            ? data?.appliedCssClassNames
            : "gbh-global-tab"
        } ${columnClassNames ? columnClassNames : ""} ${isOpen ? "open" : ""}`}
      >
        <div
          className={`cmp-tabs ${darkThemeFlag ? "dark-theme" : "light-theme"}`}
        >
          <span
            className="gbh-hamber-menu gbh-close icon-Close-Medium"
            aria-label="close button"
            tabIndex="0"
            onClick={() => {
              toggleMenu()
              onClickNav({ "cq:panelTitle": navOpen })
              addAnalyticsOnClose()
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </span>
          <ol
            onMouseLeave={() => handleMouseLeave()}
            role="tablist"
            className="cmp-tabs__tablist"
            aria-multiselectable="false"
            onMouseOver={event => {
              onClickNav(data[":items"][event.target.id])
            }}
          >
            {data[":itemsOrder"] &&
              data[":itemsOrder"].map((key, index) => {
                const item = data[":items"][key]

                return (
                  <li
                    id={key}
                    role="tab"
                    className={`cmp-tabs__tab ${
                      selectedTab == item["cq:panelTitle"]
                        ? "cmp-tabs__tab--active"
                        : ""
                    }`}
                    tabIndex="0"
                    key={`navItem-${index}`}
                  >
                    {item["cq:panelTitle"]}
                  </li>
                )
              })}
            {data?.mainTabId &&
              Object.keys(data?.mainTabId).map((key, index) => {
                return (
                  <li
                    role="tab"
                    className="cmp-tabs__tab"
                    tabIndex="0"
                    key={`navItem-${index}`}
                  >
                    {key}
                  </li>
                )
              })}
            {data[":itemsOrder"]
              ? data[":itemsOrder"]?.map((key, index) => {
                  const element = { ...data[":items"] }[key]

                  if (element) {
                    const item =
                      element[":items"]?.globalnavigation ||
                      element[":items"][
                        Object.keys(element[":items"])?.find(key =>
                          key.includes("globalnavigation_v3")
                        )
                      ]
                    const menuType = item?.navigationMenuType
                    return (
                      <div
                        key={`navTabs-${index}`}
                        role="tabpanel"
                        tabIndex="-1"
                        className={`cmp-tabs__tabpanel ${
                          navOpen === element["cq:panelTitle"]
                            ? "cmp-tabs__tabpanel--active"
                            : ""
                        }`}
                      >
                        <div className={`${staticClasses.CONTAINER}`}>
                          <div className="cmp-container">
                            <div className="aem-Grid aem-Grid--11 aem-Grid--default--11 ">
                              <div className="globalnavigation aem-GridColumn aem-GridColumn--default--11">
                                <div
                                  className="gbh-full-w-menu"
                                  onMouseLeave={handleMouseLeave}
                                >
                                  <div className="bg-dim">
                                    <div
                                      className="gbh-full-w-menu__inner"
                                      id="Mouse id"
                                    >
                                      <div className="gbh-full-max-width">
                                        <div
                                          className={`menu-container ${
                                            darkThemeFlag
                                              ? "dark-theme"
                                              : "light-theme"
                                          } ${menuType}`}
                                        >
                                          <div
                                            className={`gbh-full-w-menu__left-cont ${menuType}`}
                                          >
                                            <div className="gbh-full-w-menu__left-cont__top">
                                              <div className="levelZero">
                                                <ul className="nav flex-column gbh-full-w-menu__left-cont__menu-list">
                                                  <button className="gbh-mob-back-btn">
                                                    Menu
                                                  </button>
                                                  <div className="level-one-container">
                                                    <div className="level-one-sub-container">
                                                      {item?.navTitle ? (
                                                        <div className="group-title">
                                                          {item?.navTitle}
                                                        </div>
                                                      ) : null}

                                                      <div className="level-one-nav-multi">
                                                        {item?.levelOneNavMulti?.map(
                                                          (navItem, index) => {
                                                            return (
                                                              <div
                                                                key={
                                                                  navItem +
                                                                  index
                                                                }
                                                                className={`levelTwoMapping ${
                                                                  menuType +
                                                                  (item
                                                                    ?.levelOneNavMulti
                                                                    ?.length ===
                                                                  1
                                                                    ? "-one-col"
                                                                    : "")
                                                                }`}
                                                              >
                                                                {navItem?.levelTwoNavMulti?.map(
                                                                  (
                                                                    levelTwo,
                                                                    index
                                                                  ) => {
                                                                    return (
                                                                      <li
                                                                        key={
                                                                          navItem +
                                                                          index
                                                                        }
                                                                        className={
                                                                          levelTwo?.groupTitle?.includes(
                                                                            "carrot-icon"
                                                                          )
                                                                            ? "carot-title"
                                                                            : ""
                                                                        }
                                                                      >
                                                                        <div className="menu-item-tiles">
                                                                          <a
                                                                            className={`menuItems ${menuType} ${
                                                                              levelTwo?.groupTitle?.includes(
                                                                                "carrot-icon"
                                                                              )
                                                                                ? "carot-icon"
                                                                                : ""
                                                                            }`}
                                                                            href={levelTwo?.groupLink
                                                                              ?.split(
                                                                                process
                                                                                  .env
                                                                                  .NEXT_PUBLIC_INTERNALLINKPATH
                                                                              )
                                                                              .join(
                                                                                ""
                                                                              )
                                                                              .replace(
                                                                                ".html",
                                                                                ""
                                                                              )}
                                                                            dangerouslySetInnerHTML={{
                                                                              __html:
                                                                                DOMPurify.sanitize(
                                                                                  levelTwo?.groupTitle
                                                                                ),
                                                                            }}
                                                                            onClick={() =>
                                                                              servicesClick(
                                                                                {
                                                                                  text: levelTwo?.groupTitle,
                                                                                  link: levelTwo?.groupLink,
                                                                                  Leveltwo:
                                                                                    selectedTab,
                                                                                }
                                                                              )
                                                                            }
                                                                          ></a>
                                                                          {levelTwo?.groupTitle?.includes(
                                                                            "carrot-icon"
                                                                          ) ? (
                                                                            <div className="navigation-img">
                                                                              <img
                                                                                src={
                                                                                  darkThemeFlag
                                                                                    ? caretimgWhite
                                                                                    : caretimg
                                                                                }
                                                                                alt="caret-icon"
                                                                              />
                                                                            </div>
                                                                          ) : null}
                                                                          {getInternalLinkURLS(
                                                                            levelTwo?.groupLink
                                                                          ) ? (
                                                                            <div className="navigation-external-img">
                                                                              <img
                                                                                src={
                                                                                  darkThemeFlag
                                                                                    ? externalIconWhite
                                                                                    : externalicon
                                                                                }
                                                                                alt="external-icon"
                                                                              />
                                                                            </div>
                                                                          ) : null}
                                                                        </div>
                                                                        {levelTwo?.levelThreeNavMulti?.map(
                                                                          (
                                                                            columnItems,
                                                                            index
                                                                          ) => {
                                                                            return (
                                                                              <li
                                                                                key={`navItem-${index}`}
                                                                                className="nav-item"
                                                                              >
                                                                                <a
                                                                                  href={columnItems?.link
                                                                                    ?.split(
                                                                                      process
                                                                                        .env
                                                                                        .NEXT_PUBLIC_INTERNALLINKPATH
                                                                                    )
                                                                                    .join(
                                                                                      ""
                                                                                    )
                                                                                    .replace(
                                                                                      ".html",
                                                                                      ""
                                                                                    )}
                                                                                  onClick={() =>
                                                                                    servicesClick(
                                                                                      {
                                                                                        text: columnItems.label,
                                                                                        link: columnItems?.link,
                                                                                        Leveltwo:
                                                                                          levelTwo?.groupTitle,
                                                                                        L1: selectedTab,
                                                                                      }
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  <span
                                                                                    className="menu-labels"
                                                                                    dangerouslySetInnerHTML={{
                                                                                      __html:
                                                                                        DOMPurify.sanitize(
                                                                                          columnItems.label
                                                                                        ),
                                                                                    }}
                                                                                  />
                                                                                  {getInternalLinkURLS(
                                                                                    columnItems?.link
                                                                                  ) ? (
                                                                                    <div className="navigation-external-img">
                                                                                      <img
                                                                                        src={
                                                                                          darkThemeFlag
                                                                                            ? externalIconWhite
                                                                                            : externalicon
                                                                                        }
                                                                                        alt="external-icon"
                                                                                      />
                                                                                    </div>
                                                                                  ) : null}
                                                                                </a>
                                                                              </li>
                                                                            )
                                                                          }
                                                                        )}
                                                                      </li>
                                                                    )
                                                                  }
                                                                )}
                                                              </div>
                                                            )
                                                          }
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="gbh-full-w-menu__right-img">
                                            {item?.multiCTA?.length > 0 ? (
                                              <div className="right-img-cta">
                                                {item?.multiCTA?.map(
                                                  (ctaItem, index) => {
                                                    return (
                                                      <ol
                                                        key={`navItem-${index}`}
                                                        className="multicta"
                                                      >
                                                        <div className="navigationcta">
                                                          <div className="ctalable">
                                                            <a
                                                              rel="noreferrer"
                                                              target={
                                                                ctaItem?.newTabCTA
                                                                  ? "_blank"
                                                                  : "_self"
                                                              }
                                                              href={ctaItem?.ctaLink
                                                                ?.split(
                                                                  process.env
                                                                    .NEXT_PUBLIC_INTERNALLINKPATH
                                                                )
                                                                .join("")
                                                                .replace(
                                                                  ".html",
                                                                  ""
                                                                )}
                                                              onClick={() =>
                                                                servicesClick({
                                                                  text: ctaItem.ctaLabel,
                                                                  link: ctaItem?.ctaLink,
                                                                  Leveltwo:
                                                                    selectedTab,
                                                                })
                                                              }
                                                            >
                                                              <span
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    DOMPurify.sanitize(
                                                                      ctaItem.ctaLabel
                                                                    ),
                                                                }}
                                                              />
                                                            </a>
                                                          </div>
                                                          <div className="cta-img">
                                                            <img
                                                              src={
                                                                darkThemeFlag
                                                                  ? caretimgWhite
                                                                  : caretimg
                                                              }
                                                              alt="caret-icon"
                                                            />
                                                          </div>
                                                        </div>
                                                      </ol>
                                                    )
                                                  }
                                                )}
                                              </div>
                                            ) : null}

                                            <div
                                              className={`block-images ${menuType}`}
                                            >
                                              {item?.firstImagesblock ? (
                                                <div
                                                  className={`${element?.appliedCssClassNames} ${menuType}`}
                                                >
                                                  <a
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    target={
                                                      item?.firstImagesblock
                                                        ?.newTabFirstImage
                                                        ? "_blank"
                                                        : "_self"
                                                    }
                                                    href={item?.firstImagesblock?.firstImageLink
                                                      ?.split(
                                                        process.env
                                                          .NEXT_PUBLIC_INTERNALLINKPATH
                                                      )
                                                      .join("")
                                                      .replace(".html", "")}
                                                    rel="noreferrer"
                                                    onClick={() => {
                                                      servicesClick({
                                                        text: item
                                                          ?.firstImagesblock
                                                          ?.ctaLabel,
                                                        link: item
                                                          ?.firstImagesblock
                                                          ?.firstImageLink,
                                                        Leveltwo: selectedTab,
                                                        L1: "",
                                                        imgcheck: true,
                                                      })
                                                    }}
                                                  >
                                                    <SmartCropImage
                                                      url={
                                                        item?.firstImagesblock
                                                          ?.firstImageFileReference
                                                      }
                                                      className="cmp-image__image"
                                                      smartCrop={smartCrop}
                                                      altText={
                                                        item?.firstImagesblock
                                                          ?.altText ?? ""
                                                      }
                                                    />
                                                  </a>
                                                  <span
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        DOMPurify.sanitize(
                                                          item?.firstImagesblock
                                                            ?.firstImageTitle
                                                        ),
                                                    }}
                                                  />
                                                  {item?.firstImagesblock
                                                    ?.ctaLink ? (
                                                    <div className="image-cta">
                                                      <div className="div-of-cta">
                                                        <a
                                                          href={item?.firstImagesblock?.ctaLink
                                                            ?.split(
                                                              process.env
                                                                .NEXT_PUBLIC_INTERNALLINKPATH
                                                            )
                                                            .join("")
                                                            .replace(
                                                              ".html",
                                                              ""
                                                            )}
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              DOMPurify.sanitize(
                                                                item
                                                                  ?.firstImagesblock
                                                                  ?.ctaLabel
                                                              ),
                                                          }}
                                                          onClick={() => {
                                                            servicesClick({
                                                              text: item
                                                                ?.firstImagesblock
                                                                ?.ctaLabel,
                                                              link: item
                                                                ?.firstImagesblock
                                                                ?.ctaLink,
                                                              Leveltwo:
                                                                selectedTab,
                                                            })
                                                          }}
                                                        ></a>
                                                      </div>
                                                      <div className="cta-link-caret">
                                                        <img
                                                          src={
                                                            darkThemeFlag
                                                              ? caretimgWhite
                                                              : caretimg
                                                          }
                                                          alt="caret-icon"
                                                        />
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              ) : null}
                                              {item?.secondImagesblock &&
                                              item?.levelOneNavMulti?.length <
                                                3 ? (
                                                <div
                                                  className={`${element?.appliedCssClassNames} ${menuType}`}
                                                >
                                                  <a
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    target={
                                                      item?.secondImagesblock
                                                        ?.newTabSecondImage
                                                        ? "_blank"
                                                        : "_self"
                                                    }
                                                    href={item?.secondImagesblock?.secondImageLink
                                                      ?.split(
                                                        process.env
                                                          .NEXT_PUBLIC_INTERNALLINKPATH
                                                      )
                                                      .join("")
                                                      .replace(".html", "")}
                                                    rel="noreferrer"
                                                    onClick={() => {
                                                      servicesClick({
                                                        text: item
                                                          ?.secondImagesblock
                                                          ?.ctaLabel,
                                                        link: item
                                                          ?.secondImagesblock
                                                          ?.secondImageLink,
                                                        Leveltwo: selectedTab,
                                                        L1: "",
                                                        imgcheck: true,
                                                      })
                                                    }}
                                                  >
                                                    <SmartCropImage
                                                      url={
                                                        item?.secondImagesblock
                                                          ?.secondImageFileReference
                                                      }
                                                      className="cmp-image__image"
                                                      smartCrop={smartCrop}
                                                      altText={
                                                        item?.secondImagesblock
                                                          ?.altText ?? ""
                                                      }
                                                    />
                                                  </a>
                                                  <span
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        DOMPurify.sanitize(
                                                          item
                                                            ?.secondImagesblock
                                                            ?.secondImageTitle
                                                        ),
                                                    }}
                                                  />
                                                  {item?.secondImagesblock
                                                    ?.ctaLink ? (
                                                    <div className="image-cta">
                                                      <div className="div-of-cta">
                                                        <a
                                                          href={item?.secondImagesblock?.ctaLink
                                                            ?.split(
                                                              process.env
                                                                .NEXT_PUBLIC_INTERNALLINKPATH
                                                            )
                                                            .join("")
                                                            .replace(
                                                              ".html",
                                                              ""
                                                            )}
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              DOMPurify.sanitize(
                                                                item
                                                                  ?.secondImagesblock
                                                                  ?.ctaLabel
                                                              ),
                                                          }}
                                                          onClick={() => {
                                                            servicesClick({
                                                              text: item
                                                                ?.secondImagesblock
                                                                ?.ctaLabel,
                                                              link: item
                                                                ?.secondImagesblock
                                                                ?.ctaLink,
                                                              Leveltwo:
                                                                selectedTab,
                                                            })
                                                          }}
                                                        ></a>
                                                      </div>
                                                      <div className="cta-link-caret">
                                                        <img
                                                          src={
                                                            darkThemeFlag
                                                              ? caretimgWhite
                                                              : caretimg
                                                          }
                                                          alt="caret-icon"
                                                        />
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="productbg" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                })
              : null}
          </ol>
        </div>
      </div>
    </div>
  )
}

export default HeaderNavTabsV3
