import React, { useState, useRef, useEffect } from "react"
import Dropdown from "react-bootstrap/Dropdown"
import useViewport from "@/hooks/useViewport"
import RadioButton from "@/components/core/RadioButton/RadioButton"
import ToggleView from "@/components/PartsViewer/v2/GridAndListPanel/ToggleView"
import { sortOptions } from "@/components/PartsViewer/v2/utils.js"
import {
  SortByIcon,
  SortByDropDownIcon,
  IconClose,
} from "@/components/PartsViewer/v2/icon.js"

const SortDropdown = props => {
  const {
    partController,
    visibleData,
    title,
    sku,
    setlistView,
    listView,
    isListExtend,
  } = props
  const menuRef = useRef(null)
  const [sort, setSort] = useState("asc")
  const [sortDesc, setSortDesc] = useState("A-Z")
  const device = useViewport()
  const [isOpen, setIsOpen] = useState(false)
  const isDesktop = device.isLargeScreen || device.isExtraLargeScreen
  const toogleDropDown = () => {
    setIsOpen(prevOpen => !prevOpen)
  }
  const sortBy = (e, order) => {
    partController.actions.filterProduct(order.key)
    partController.actions.fetchForCpiPromise(visibleData)
    setSortDesc(order.label)
    setIsOpen(true)
    setSort(order.key)
  }
  const openDropDownMenu = () => {
    const dropMenu = menuRef.current
    if (dropMenu) {
      if (device?.isSmallScreen) {
        dropMenu.style.position = "fixed"
        dropMenu.style.inset = "auto 0px 0px"
        dropMenu.style.transform = "none"
        dropMenu.style.transition = "all 0.5s ease-in-out"
      }
    }
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      openDropDownMenu()
    }, 0)
    return () => clearTimeout(timer)
  }, [isOpen])
  return (
    <>
      <Dropdown
        onToggle={toogleDropDown}
        className={`dropdown border-0 ${
          device.isSmallScreen ? "mobile-dropdown" : ""
        }`}
      >
        <Dropdown.Toggle
          variant="link"
          className={`sorting-btn btn btn-expand border-0 ${
            isListExtend ? "full-screen-view" : "small-screen-view"
          }`}
          id="dropdown-basic"
          data-testid="sort-dropdown"
        >
          <label className="sort-label">
            <span>{isDesktop ? `Sort By: ${sortDesc}` : "Sort"}</span>
            {isDesktop ? <SortByDropDownIcon /> : <SortByIcon />}
            {isDesktop ? (
              <div className="tool-tip-custom bottom">
                <span>Sort Product</span>
              </div>
            ) : null}
          </label>

          {device.isSmallScreen ? (
            <ToggleView {...{ listView, setlistView, device, title, sku }} />
          ) : (
            ""
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu
          show={isOpen}
          ref={menuRef}
          onEntered={openDropDownMenu}
          className={`${device.isSmallScreen ? "" : "menu-dropdown"}`}
        >
          {device.isSmallScreen ? (
            <>
              <div className="sort-div d-flex justify-content-between align-items-center mx-3">
                <div className="Sort-by-title">Sort </div>
                <Dropdown.Item className="close-icon">
                  <button onClick={toogleDropDown} className="close-menu">
                    <IconClose />
                  </button>
                </Dropdown.Item>
              </div>
            </>
          ) : (
            ""
          )}

          {sortOptions.map(val => (
            <Dropdown.Item
              key={val.key}
              data-testid={`sort-${val.key}`}
              className={`dropdown-item fw-400 ${
                sort === val.key ? "sort-by" : ""
              }`}
              onClick={event => sortBy(event, val)}
            >
              {device.isSmallScreen ? (
                <div className="sort__radio-group">
                  <RadioButton
                    id={val.key}
                    name={val.key}
                    value={val.label}
                    checked={sort === val.key}
                  />
                </div>
              ) : (
                <>{val.label}</>
              )}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
        {device.isSmallScreen ? <div className="mobile-overlay"></div> : ""}
      </Dropdown>
    </>
  )
}

export default SortDropdown
