import React, { useEffect, useRef, useState } from "react"
import SmartCropImage from "@/components/core/SmartCropImage"
import useSmartCrop from "@/hooks/useSmartCrop"
import ProductCard from "@/components/productCard/v3/ProductCard"
import HotspotProductCard from "@/components/productCard/Hotspot"
import PlusIcon from "@/public/icons/plus-button-shop.svg"
import HotSpotSmall from "@/public/icons/hotspot-button-small.svg"
import styles from "@/components/ShopTheRoomDetail/v1/index.module.scss"
import Image from "next/image"
import ShopTheRoomCardModalView from "@/components/ShopTheRoomCard/v1/ShopTheRoomCardModalView"
import ShopTheRoomToaster from "@/components/ShopTheRoomDetail/v1/ShopTheRoomToaster"
import Overlay from "@/components/ShopTheRoomDetail/v1/Overlay"
import htmlParser from "html-react-parser"
const ShopTheRoomDetail = ({ data: authorData }) => {
  const {
    cardTitle = "",
    cardDescription = "",
    actions = [],
    productsLabel = "",
    hideProductsLabel = "",
    cardFirstImage = "",
    cardSecondImage = "",
    productsView = "",
    skuIds = [],
    presetConfigs,
    modalTitle = "",
    modalDesc = "",
    modalCTATitle = "",
    modalCTALink = "",
    linkNewTabToaster = "",
    enableFavorite = false,
  } = authorData
  const popCardDefaultValues = {
    visible: false,
    top: 0,
    left: 0,
    skuId: "",
    index: -1,
  }
  const firstImageHotspots = cardFirstImage?.hotspots
  const secondImageHotspots = cardSecondImage?.hotspots
  const [viewMoreToggle, setViewMoreToggle] = useState(false)
  const [showHotspots, setShowHotSpots] = useState(false)
  const [invokedFrom, setInvokedFrom] = useState("button")
  const smartCrop = useSmartCrop()
  const imageContainer = useRef(null)
  const [i, setI] = useState(0)
  const [x0, setX0] = useState(null)
  const [locked, setLocked] = useState(false)
  const [tx, setTx] = useState("")
  const [N, setN] = useState(1)
  const [toggle, setToggle] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState("")
  const [hotspotClicked, setHotspotClicked] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [firstImageActive, setFirstImageActive] = useState(null)
  const firstImageContainerRef = useRef(null)
  const secondImageContainerRef = useRef(null)
  const [firstImagePopup, setFirstImagePopup] = useState({
    ...popCardDefaultValues,
  })
  const [secondImagePopup, setSecondImagePopup] = useState({
    ...popCardDefaultValues,
  })
  let isMobile
  if (typeof window !== "undefined") {
    isMobile = window.innerWidth <= 1037
  }
  useEffect(() => {
    if (isMobile) {
      const imageParent = imageContainer.current
      const actualN = imageParent.children.length
      setN(actualN)
    }
  }, [isMobile])
  const unify = e => {
    return e.changedTouches ? e.changedTouches[0] : e
  }
  const hotspotClass = `hotspotsmallimg ${showHotspots ? "" : "hidden"}`
  useEffect(() => {
    if (!viewMoreToggle) {
      setSelectedProduct("")
    }
  }, [viewMoreToggle])
  const handleModalToggle = from => {
    if (from === "ViewMore") {
      setIsModalOpen(true)
    } else {
      setIsModalOpen(false)
    }
  }
  const handleMove = e => {
    if (locked) {
      const dx = unify(e).clientX - x0
      const s = Math.sign(dx)
      let tempI = i
      if ((tempI > 0 || s < 0) && (tempI < N - 1 || s > 0)) tempI -= s
      setI(tempI)
      setTx("0px")
      setLocked(false)
      setToggle(true)
      setX0(null)
    }
  }
  const hanldeDrag = e => {
    e.preventDefault()
    if (locked) setTx(`${Math.round(unify(e).clientX - x0)}px`)
  }

  const hanldeLock = e => {
    setX0(unify(e).clientX)
    setLocked(true)
    setToggle(false)
  }

  const handleViewMore = () => {
    setShowHotSpots(!showHotspots)
    if (productsView !== "cardView") {
      setViewMoreToggle(!viewMoreToggle)
    }
    handleModalToggle("ViewMore")
  }

  const handleHotspotClick = skuId => {
    setHotspotClicked(!hotspotClicked)
    setSelectedProduct(skuId)
  }

  const handleImageIconClick = (e, skuId, index, imageType) => {
    if (imageType == "first" && firstImagePopup?.index == index) {
      setFirstImagePopup({ ...firstImagePopup, visible: false, index: -1 })
      return
    }
    if (imageType == "second" && secondImagePopup?.index == index) {
      setSecondImagePopup({ ...firstImagePopup, visible: false, index: -1 })
      return
    }
    let parentRect
    if (imageType == "first") {
      parentRect = firstImageContainerRef.current?.getBoundingClientRect()
    }
    if (imageType == "second") {
      parentRect = secondImageContainerRef.current?.getBoundingClientRect()
    }
    const icon = e.currentTarget?.getBoundingClientRect()
    const parentHeight = parentRect.height
    const parentWidth = parentRect.width
    const popupWidth = 250
    const popupHeight = 384
    const margin = 1
    const positions = [
      {
        top: icon.top - parentRect.top - popupHeight - margin,
        left: icon.left - parentRect.left,
      },
      {
        top: icon.top - parentRect.top,
        left: icon.right - parentRect.left + margin,
      },
      {
        top: icon.bottom - parentRect.top + margin,
        left: icon.left - parentRect.left,
      },
      {
        top: icon.top - parentRect.top,
        left: icon.left - parentRect.left - popupWidth - margin,
      },
    ]

    let finalPosition = positions.find(pos => {
      if (
        pos.top >= 0 &&
        pos.top + popupHeight <= parentHeight &&
        pos.left >= 0 &&
        pos.left + popupWidth <= parentWidth
      ) {
        return true
      } else {
        return false
      }
    })
    if (!finalPosition) {
      finalPosition = positions.reduce(
        (bestPos, pos) => {
          const overflowX =
            Math.max(0, pos.left + popupWidth - parentWidth) +
            Math.max(0, -pos.left)
          const overflowY =
            Math.max(0, pos.top + popupHeight - parentHeight) +
            Math.max(0, -pos.top)
          const overflow = overflowX + overflowY
          return overflow < bestPos.overflow ? { ...pos, overflow } : bestPos
        },
        { overflow: Infinity }
      )
    }

    if (Math.sign(finalPosition?.top) === -1) {
      finalPosition.top = 1
    }
    if (finalPosition.left + 250 > parentWidth) {
      const gap = finalPosition.left + 250 - parentWidth
      finalPosition.left = finalPosition.left - gap
    }
    if (Math.sign(finalPosition?.left) === -1) {
      finalPosition.left = 1
    }
    if (finalPosition.top + 352 > parentHeight) {
      const gap = finalPosition.top + 352 - parentHeight
      finalPosition.top = finalPosition.top - gap
    }
    if (imageType == "first") {
      setFirstImagePopup({
        visible: true,
        top: finalPosition?.top,
        left: finalPosition?.left,
        skuId,
        index,
      })
    }
    if (imageType == "second") {
      setSecondImagePopup({
        visible: true,
        top: finalPosition?.top,
        left: finalPosition?.left,
        skuId,
        index,
      })
    }
  }

  const hadleHotspotImgeOutSideClick = (imageTyep = "") => {
    const popObj = {
      visible: false,
      top: 0,
      left: 0,
      skuId: "",
      index: -1,
    }
    if (imageTyep === "first") {
      setFirstImagePopup({ ...popObj })
    }
    if (imageTyep === "second") {
      setSecondImagePopup({ ...popObj })
    }
  }
  return (
    <div className={styles.shopTheRoomDetailWrapper}>
      <div className="shop-the-room-detail-container">
        <div className="text-images-container">
          <div className="shop-the-room-detail-text-container">
            <div className="shop-the-room-detail-textbox">
              <p className="card-title">
                {cardTitle ? htmlParser(cardTitle) : null}
              </p>
              <p className="card-description">
                {cardDescription ? htmlParser(cardDescription) : null}
              </p>
              <div className="ctas-box">
                {actions?.map((item, index) => (
                  <a
                    key={index}
                    rel="noreferrer"
                    target={item?.newTab === "true" ? "_blank" : "_self"}
                    href={item?.url}
                  >
                    {item?.title}
                  </a>
                ))}
              </div>
              <div className="shop-the-room-detail-icon-label-container">
                {isMobile ? (
                  <div className="view-more-products-button">
                    <p
                      className="view-more-button-text"
                      role="button"
                      onClick={() => setViewMoreToggle(!viewMoreToggle)}
                    >
                      {viewMoreToggle
                        ? htmlParser(hideProductsLabel)
                        : htmlParser(productsLabel)}
                    </p>
                  </div>
                ) : (
                  <div className="view-more-products-button">
                    <span role="button" onClick={handleViewMore}>
                      <Image src={PlusIcon} alt="" />
                    </span>
                    <p className="view-more-button-text">
                      {htmlParser(productsLabel)}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="shop-the-room-detail-image-container">
            {isMobile ? (
              <div
                className={`swipe-image ${toggle ? "smooth" : ""}`}
                style={{ "--n": N, "--i": i, "--tx": tx }}
                ref={imageContainer}
                onTouchStart={hanldeLock}
                onMouseMove={hanldeDrag}
                onTouchMove={hanldeDrag}
                onTouchEnd={handleMove}
              >
                {cardFirstImage && (
                  <SmartCropImage
                    url={cardFirstImage.imageLink}
                    altText=""
                    smartCrop={smartCrop}
                  />
                )}
                {cardSecondImage && (
                  <SmartCropImage
                    url={cardSecondImage.imageLink}
                    altText=""
                    smartCrop={smartCrop}
                  />
                )}
              </div>
            ) : (
              <>
                {cardFirstImage && (
                  <div
                    className="first-image-container"
                    style={{ position: "relative" }}
                    ref={firstImageContainerRef}
                  >
                    <SmartCropImage
                      url={cardFirstImage.imageLink}
                      altText={cardFirstImage?.altText}
                      smartCrop={smartCrop}
                      handleImgeClick={() => {
                        hadleHotspotImgeOutSideClick("first")
                      }}
                    />
                    {productsView !== "toasterView" &&
                      firstImageHotspots?.map((hotspot, index) => {
                        const xCord = JSON.parse(
                          hotspot?.coordinates.split(",")[0]
                        )
                        const width = JSON.parse(cardFirstImage?.width)
                        const xCoordinate = (xCord / width) * 100
                        const yCord = JSON.parse(
                          hotspot?.coordinates.split(",")[1]
                        )
                        const height = JSON.parse(cardFirstImage?.height)
                        const yCoordinate = (yCord / height) * 100
                        const skuId = hotspot?.skuId
                        return (
                          <div
                            key={`${skuId}`}
                            style={{
                              position: "absolute",
                              left: `calc(${xCoordinate}% - 20px)`,
                              top: `calc(${yCoordinate}%  - 20px)`,
                            }}
                          >
                            <Image
                              src={HotSpotSmall}
                              alt=""
                              className={hotspotClass}
                              onClick={e => {
                                if (productsView == "cardView") {
                                  handleImageIconClick(e, skuId, index, "first")
                                } else {
                                  handleHotspotClick(skuId)
                                }
                                setInvokedFrom("")
                                setFirstImageActive(
                                  firstImageActive == index ? null : index
                                )
                              }}
                            />
                          </div>
                        )
                      })}
                    {firstImagePopup?.visible ? (
                      <div
                        className="product-popup"
                        style={{
                          left: `${firstImagePopup.left}px`,
                          top: `${firstImagePopup.top}px`,
                        }}
                      >
                        <HotspotProductCard
                          key={firstImagePopup?.skuId}
                          authorData={{
                            skewId: firstImagePopup?.skuId,
                            presetConfigs: presetConfigs,
                          }}
                          selectedProduct={selectedProduct}
                          hotspotClicked={hotspotClicked}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                {cardSecondImage && (
                  <div
                    className="second-image-container"
                    style={{ position: "relative" }}
                    ref={secondImageContainerRef}
                  >
                    <SmartCropImage
                      url={cardSecondImage.imageLink}
                      altText={cardSecondImage?.altText}
                      smartCrop={smartCrop}
                      handleImgeClick={() => {
                        hadleHotspotImgeOutSideClick("second")
                      }}
                    />
                    {productsView !== "toasterView"
                      ? secondImageHotspots?.map((hotspot, index) => {
                          const xCord = JSON.parse(
                            hotspot?.coordinates.split(",")[0]
                          )
                          const width = JSON.parse(cardSecondImage?.width)
                          const xCoordinate = (xCord / width) * 100
                          const yCord = JSON.parse(
                            hotspot?.coordinates.split(",")[1]
                          )
                          const height = JSON.parse(cardSecondImage?.height)
                          const yCoordinate = (yCord / height) * 100
                          const skuId = hotspot?.skuId
                          return (
                            <div
                              key={`${skuId}`}
                              style={{
                                position: "absolute",
                                left: `calc(${xCoordinate}% - 20px)`,
                                top: `calc(${yCoordinate}%  - 20px)`,
                              }}
                            >
                              <Image
                                src={HotSpotSmall}
                                alt=""
                                className={hotspotClass}
                                onClick={e => {
                                  if (productsView === "cardView") {
                                    handleImageIconClick(
                                      e,
                                      skuId,
                                      index,
                                      "second"
                                    )
                                  } else {
                                    handleHotspotClick(skuId)
                                  }
                                  setInvokedFrom("")
                                }}
                              />
                            </div>
                          )
                        })
                      : null}
                    {secondImagePopup?.visible ? (
                      <div
                        className="product-popup"
                        style={{
                          left: `${secondImagePopup.left}px`,
                          top: `${secondImagePopup.top}px`,
                        }}
                      >
                        <HotspotProductCard
                          key={secondImagePopup?.skuId}
                          authorData={{
                            skewId: secondImagePopup?.skuId,
                            presetConfigs: presetConfigs,
                          }}
                          selectedProduct={selectedProduct}
                          hotspotClicked={hotspotClicked}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {viewMoreToggle && productsView === "stackView" && (
          <div className="view-more-products-container">
            {skuIds.map((sku, id) => {
              return (
                <ProductCard
                  isFavoriteIcon={enableFavorite}
                  key={sku}
                  authorData={{
                    skewId: sku,
                    presetConfigs: presetConfigs,
                  }}
                  selectedProduct={selectedProduct}
                  hotspotClicked={hotspotClicked}
                />
              )
            })}
          </div>
        )}
        {viewMoreToggle && productsView === "carouselView" && (
          <ShopTheRoomCardModalView
            skuIds={skuIds}
            presetConfigs={presetConfigs}
            isModal={false}
            selectedProduct={selectedProduct}
            hotspotClicked={hotspotClicked}
            setHotspotClicked={setHotspotClicked}
            invokedFrom={invokedFrom}
            setInvokedFrom={setInvokedFrom}
          />
        )}
        {!isMobile && isModalOpen && productsView === "toasterView" ? (
          <ShopTheRoomToaster
            enableFavorite={enableFavorite}
            modalTitle={modalTitle}
            modalDesc={modalDesc}
            modalCtaLink={modalCTALink}
            modalCtaTitle={modalCTATitle}
            setIsModalOpen={setIsModalOpen}
            isOpen={isModalOpen}
            onClose={handleModalToggle}
            cardTitle={cardTitle}
            skuIds={skuIds}
            presetConfigs={presetConfigs}
            linkNewTabToaster={linkNewTabToaster}
          />
        ) : null}
        {!isMobile && productsView === "toasterView" ? (
          <Overlay isOpen={isModalOpen} viewMore={viewMoreToggle} />
        ) : null}
      </div>
    </div>
  )
}
export default ShopTheRoomDetail
