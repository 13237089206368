import React from "react"
import itemImage from "@/public/scout_icon/dummy_image.jpg"

const ListCardLoading = () => {
  return (
    <div
      className={`card d-flex load-widgets border-active flex-row justify-content-between`}
    >
      <div className={`d-flex flex-row justify-content-start`}>
        <span className={`thumb-list loading-animation shimmer `}>
          <img src={itemImage?.src} alt="loading" />
        </span>
        <div className="width-full">
          <div className="d-flex">
            {Array.from({ length: 5 }, (val, index) => index + 1).map(val => (
              <p key={val} class="shimmer-loading__para shimmer width-20"></p>
            ))}
            <p class=" width-16 loading-part mx-1 shimmer-loading__para shimmer"></p>
          </div>
          <div className={`d-flex flex-column justify-content-start pt-5`}>
            <p class="shimmer-loading__para shimmer width-191 loading-part"></p>
            <p class="shimmer-loading__para shimmer width-102 loading-part"></p>
            <div className="d-flex">
              <p class="shimmer-loading__para shimmer width-15 loading-part"></p>
              <p class="shimmer-loading__para shimmer width-15 mx-3 loading-part"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListCardLoading
