import React, { useCallback, useEffect, useRef, useState } from "react"
import useScreenOrientation from "@/hooks/useScreenOrientation"
import useWindowDimensions from "@/hooks/useWindowDimensions"
import {
  convertStringToBoolean,
  createIdFromString,
  getRandom,
  sanitizeInnerHtml,
} from "@/utils/helper"
import Action from "@/components/Default/Teaser/v1/Action"
import Description from "@/components/Default/Teaser/v1/Description"
import { useSelector } from "react-redux"
import { selectGenericState } from "@/store/features/genericSlice"
import { useInView } from "react-intersection-observer"
import { VIDEO_EVENTS } from "@/constants/index"
import { getConfig } from "@/constants/config"
import {
  onClickHeroVideo,
  onFavVideo,
  onPlayVideoInModal,
  onShareVideo,
  onPlayVideo,
  onVideoInteraction,
  onVolumeChange,
  onPlayInspireVideo,
  onPlayInspireVideoClose,
} from "@/utils/videoHelper"
import SocialShare from "@/components/cart/OrderSummary/SocialShare"

const Video = props => {
  const {
    data: {
      videoTitle = "",
      videoMobile = "",
      videoMobileLandscape = "",
      videoMobilePoster = "",
      videoDesktopPoster = "",
      videoDesktop = "",
      muteEnableAutoPlay = "false",
      hideEnableAutoPlay = "false",
      alt = "",
      appliedCssClassNames = "",
      items = [],
      enableLoop = "false",
      blackBgCta = "false",
      offWhiteBg = "false",
      textLinkCta = "false",
      textLinkCtaOffWhite = "false",
      enablePopUPVideo = "false",
      modalCta = "",
      modalVideoDesktop = "",
      modalVideoMobile = "",
      modalVideoMobileLandscape = "",
      actionsEnabled: videoActionsEnabled = "false",
      teaserProperties: {
        title = "",
        description = "",
        linkURLRef = false,
        linkCTA = "",
        linkNewTab = false,
        videoPosterMobileLandscape = "",
        controlType = "",
        pageType = "",
        componentPosition = "",
        componentZone = "",
        componentLinkType = "",
        playsAboveFold = "false",
        eventName = "",
        dynamicTabName = "",
        eventAction = "",
        caption = "",
        pretitle = "",
        actionsEnabled = false,
        actions = [],
        linkAll = false,
        dataLayerLinkType = "",
        mainTitle = "",
        enableFav = false,
        enableShare = false,
        linkDescription = false,
        propertiesPageType = "",
      } = {},
    } = {},
  } = props

  const { eventPageType } = useSelector(selectGenericState)

  const { winWidth } = useWindowDimensions()
  const screenOrientation = useScreenOrientation()
  const [video, setVideo] = useState("")
  const [dynamicId, setDynamicId] = useState("")
  const [videoPlayed, setVideoPlayed] = useState(false)
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const [showSocialShare, setShowSocialShare] = useState(false)
  const [isFavourited, setIsFavourited] = useState(false)
  const [modelVideo, setModalVideo] = useState("")
  const [favVideoId, setFavVideoId] = useState("")
  const [flagState, setFlagState] = useState({
    flag25: false,
    flag50: false,
    flag75: false,
    flag100: false,
  })

  const { ref: inViewRef, inView } = useInView({
    threshold: 0.5,
  })

  const videoContainerRef = useRef(null)
  const videoRef = useRef(null)
  const inspireRef = useRef(null)
  const closeBtnRef = useRef(null)
  const [general, setGeneral] = useState(null)

  const getVideoId = video => {
    return decodeURI(video)?.split("PAWEB/")[1]?.toLowerCase()
  }

  useEffect(() => {
    getConfig().then(config => {
      setGeneral(config?.general)
    })
  }, [])

  useEffect(() => {
    if (videoTitle) {
      const dynamicId = createIdFromString(videoTitle)
      setDynamicId(dynamicId)
    }
  }, [videoTitle])

  const setVideoSource = useCallback(() => {
    if (winWidth > 1023) {
      setVideo(videoDesktop)
      setModalVideo(modalVideoDesktop)
    } else {
      if (screenOrientation?.includes("landscape")) {
        setVideo(videoMobileLandscape)
        setModalVideo(modalVideoMobileLandscape)
      } else {
        setVideo(videoMobile)
        setModalVideo(modalVideoMobile)
      }
    }
  }, [
    videoMobile,
    videoMobileLandscape,
    videoDesktop,
    screenOrientation,
    winWidth,
  ])

  useEffect(() => {
    if (inView) {
      setVideoSource()
      if (muteEnableAutoPlay === "true") videoRef?.current?.play()
    } else {
      videoRef?.current?.pause()
    }
  }, [setVideoSource, inView])

  useEffect(() => {
    if (isVideoPlaying) setVideoPlayed(true)
  }, [isVideoPlaying])

  const eventActionTabName = dynamicTabName || "zoneForVideo"
  const eventActionName = `${dynamicTabName || "rm"}:video:rm`

  const onTimeUpdate = event => {
    const videoDuration = inspireRef.current.duration
    const percent25 = Math.floor(videoDuration * 0.25)
    const percent50 = Math.floor(videoDuration * 0.5)
    const percent75 = Math.floor(videoDuration * 0.75)
    const percent100 = Math.floor(videoDuration)
    const progressTime = Math.floor(inspireRef.current.currentTime)

    if (percent100 <= progressTime && flagState.flag100 === false) {
      setFlagState(prevState => ({
        ...prevState,
        flag100: true,
      }))
      addVideoDataLayer(100)
    } else if (percent75 <= progressTime && flagState.flag75 === false) {
      setFlagState(prevState => ({
        ...prevState,
        flag75: true,
      }))
      addVideoDataLayer(75)
    } else if (percent50 <= progressTime && flagState.flag50 === false) {
      setFlagState(prevState => ({
        ...prevState,
        flag50: true,
      }))
      addVideoDataLayer(50)
    } else if (percent25 <= progressTime && flagState.flag25 === false) {
      setFlagState(prevState => ({
        ...prevState,
        flag25: true,
      }))
      addVideoDataLayer(25)
    }
  }

  const getCurrentVideoProgessPercent = () => {
    const videoDuration = inspireRef.current.duration
    const progressTime = Math.floor(inspireRef.current.currentTime)
    return (progressTime / videoDuration) * 100
  }

  const addVideoDataLayer = percentViewed => {
    let eventActionName
    let viewedPercentage
    let internalLinkName
    if (percentViewed == "videoClick") {
      eventActionName = `${eventPageType?.toLowerCase()}:behind the design:watch video`
      viewedPercentage = getCurrentVideoProgessPercent()
      internalLinkName = "watch video"
    } else if (percentViewed == "videoPlay") {
      eventActionName = `${eventPageType?.toLowerCase()}:behind the design:video play`
      viewedPercentage = getCurrentVideoProgessPercent()
      internalLinkName = "video play"
    } else if (percentViewed == "videoPause") {
      eventActionName = `${eventPageType?.toLowerCase()}:behind the design:video pause`
      viewedPercentage = getCurrentVideoProgessPercent()
      internalLinkName = `${viewedPercentage}% video viewed`
    } else if (percentViewed == "videoClose") {
      eventActionName = `${eventPageType?.toLowerCase()}:behind the design:close`
      viewedPercentage = getCurrentVideoProgessPercent()
      internalLinkName = "close"
    } else {
      eventActionName =
        percentViewed == 100
          ? `${eventPageType?.toLowerCase()}:behind the design:video complete`
          : `${eventPageType?.toLowerCase()}:${percentViewed}% video viewed`
      viewedPercentage = percentViewed
      internalLinkName =
        viewedPercentage == 100
          ? "video complete"
          : `${viewedPercentage}% video viewed`
    }
    const eventInfo = {
      clickInternalLinks: "true",
      eventAction: eventActionName,
      eventMsg: "n/a",
      eventName: eventActionName,
      eventStatus: "n/a",
      eventType: "navigation",
      internalLinkName: internalLinkName,
      internalLinkPosition: `${eventPageType?.toLowerCase()}`,
      internalLinkType: `${eventPageType?.toLowerCase()}:${
        componentPosition || "teaser"
      }`,
      internalLinkURL: "n/a",
      internalLinkZoneName: `${eventPageType?.toLowerCase()}:behind the design`,
      videoId: `${getVideoId(modelVideo)}`,
      videoName: `${
        videoTitle || componentLinkType.toLowerCase() || "hero video"
      }`,
      videoType: "user initiated",
    }
    const { adobeDataLayer: { getState } = {} } = window
    const page = (getState && getState("page")) || {}
    window.adobeDataLayer.push({
      event: "cmp:click",
      page,
      eventInfo,
    })
  }

  return (
    <section
      className={`inspire-video video teaser ${appliedCssClassNames}`}
      ref={inViewRef}
    >
      <div
        className={`cmp-teaser${
          controlType == "customControl" ? " gbh-custom-control" : ""
        }${propertiesPageType == "modalWindow" ? " gbh-model-window" : ""}${
          hideEnableAutoPlay === "true" ? " gbh-hide-mute" : ""
        }`}
        ref={videoContainerRef}
      >
        <div
          className={`cmp-teaser__image video_poster_height${
            videoPlayed ? " video-played" : ""
          }`}
          data-gbh-data-layer={`{"clickInternalLinks":"true","internalLinkPosition":"${eventPageType?.toLowerCase()}:${
            componentPosition || "teaser"
          }","internalLinkZoneName":"${eventPageType?.toLowerCase()}:${
            componentZone || "teaser"
          }","internalLinkURL":"n/a","internalLinkName":"video play","eventName":"${eventPageType?.toLowerCase()}:${eventActionTabName}","internalLinkType":"${eventPageType?.toLowerCase()}:${
            componentLinkType || "teaser"
          }","eventType":"navigation","eventAction":"${eventPageType?.toLowerCase()}:${eventActionTabName}","videoName":"${
            videoTitle || componentLinkType.toLowerCase() || "hero video"
          }"}`}
          style={showSocialShare ? { overflow: "visible" } : {}}
        >
          <span
            className={`gbh-play-video desktop${
              isVideoPlaying ? " active" : ""
            }`}
            onClick={event =>
              onPlayVideoInModal(
                event,
                propertiesPageType,
                videoContainerRef?.current,
                videoRef?.current
              )
            }
          >
            {!(hideEnableAutoPlay === "true") ? (
              <button
                className="playinside desktop"
                id={`videoinnerbtn-${dynamicId}`}
                aria-label="play video"
                onClick={() =>
                  onPlayVideo(
                    videoRef?.current,
                    res => setIsVideoPlaying(res),
                    videoDesktopPoster,
                    videoMobilePoster,
                    video,
                    isVideoPlaying
                  )
                }
              />
            ) : null}
          </span>

          {!video ? (
            <picture className="poster-img">
              <source media="(min-width:1025px)" srcSet={videoDesktopPoster} />
              <source
                media="(min-width:640px) and (max-width:1024px)"
                srcSet={videoPosterMobileLandscape}
              />
              <source media="(max-width:639px)" srcSet={videoMobilePoster} />
              {videoDesktopPoster ? (
                <img
                  src={videoDesktopPoster}
                  srcSet={videoDesktopPoster}
                  alt=""
                  className="desktop-poster"
                />
              ) : null}
            </picture>
          ) : null}

          {video ? (
            <video
              ref={videoRef}
              id={`video-desktop-${dynamicId}`}
              width="100%"
              muted={muteEnableAutoPlay === "true" ? true : false}
              autoPlay={muteEnableAutoPlay === "true" ? true : false}
              loop={muteEnableAutoPlay === "true" ? true : false}
              playsInline
              preload="metadata"
              className={`hero-video inpage-video videoelement${
                muteEnableAutoPlay === "true" ? " muted" : ""
              }${playsAboveFold === "true" ? "" : " lazy-video"}`}
              onVolumeChange={() => onVolumeChange(videoRef?.current)}
              onClick={event => onClickHeroVideo(event, videoRef?.current)}
              {...(controlType === "defaultControl" &&
              hideEnableAutoPlay === "false"
                ? { controls: true }
                : {})}
              onPlay={() =>
                onVideoInteraction(
                  VIDEO_EVENTS.PLAY,
                  () => setVideoPlayed(true),
                  res => setIsVideoPlaying(res),
                  videoRef?.current,
                  video,
                  id => setFavVideoId(id)
                )
              }
              onPause={() =>
                onVideoInteraction(
                  VIDEO_EVENTS.PAUSE,
                  () => setVideoPlayed(true),
                  res => setIsVideoPlaying(res),
                  videoRef?.current,
                  video
                )
              }
              onEnded={() =>
                onVideoInteraction(
                  VIDEO_EVENTS.ENDED,
                  () => setVideoPlayed(true),
                  res => setIsVideoPlaying(res),
                  videoRef?.current,
                  video
                )
              }
              onTimeUpdate={onTimeUpdate}
            >
              <source src={video} type="video/mp4" />
              {items.map((transcript, index) => (
                <track
                  key={`video-track-${dynamicId}-${index}`}
                  label={transcript?.languageLabel}
                  kind="subtitles"
                  srcLang={transcript?.languageCode}
                  src={transcript?.transcriptSrcPath}
                  default={items?.length ? true : false}
                />
              ))}
            </video>
          ) : (
            <video
              className={`hero-video inpage-video videoelement${
                muteEnableAutoPlay === "true" ? " muted" : ""
              }${playsAboveFold === "true" ? "" : " lazy-video"}`}
            />
          )}
          {enableLoop === "true" && modelVideo ? (
            <div>
              <video
                ref={inspireRef}
                className={`hero-video videoelement inspirevideo ${
                  muteEnableAutoPlay === "true" ? " muted" : ""
                } ${playsAboveFold === "true" ? "" : "lazy-video"}`}
                data-desktop-vid={`${modalVideoDesktop}`}
                data-mobile-vid={modalVideoMobile}
                data-mobile-lscape-vid={modalVideoMobileLandscape}
                onVolumeChange={() => onVolumeChange(inspireRef?.current)}
                id={"herovideo-desktop-1"}
                width="100%"
                playsInline
                preload="metadata"
                {...(controlType === "defaultControl" &&
                !(hideEnableAutoPlay === "false")
                  ? { controls: true }
                  : {})}
                muted={muteEnableAutoPlay === "true" ? true : false}
                autoPlay={muteEnableAutoPlay === "true" ? true : false}
                loop={muteEnableAutoPlay === "true" ? true : false}
                onTimeUpdate={onTimeUpdate}
              >
                <source src={modelVideo} type="video/mp4" />
                {items.map((transcript, index) => (
                  <track
                    key={`video-track-${dynamicId}-${index}`}
                    label={transcript?.languageLabel}
                    kind="subtitles"
                    srcLang={transcript?.languageCode}
                    src={transcript?.transcriptSrcPath}
                    default={items?.length ? true : false}
                  />
                ))}
              </video>
              <div
                class="vidio_popup_close_button inspired-video-popup-close"
                ref={closeBtnRef}
              >
                <button
                  role="button"
                  onClick={() => {
                    onPlayInspireVideoClose(
                      videoRef?.current,
                      inspireRef?.current,
                      closeBtnRef?.current,
                      addVideoDataLayer
                    )
                  }}
                >
                  <span aria-hidden="true"></span>
                </button>
              </div>
            </div>
          ) : null}
          {enableLoop === "false" && modelVideo ? (
            <div>
              <video
                ref={inspireRef}
                className={`hero-video videoelement inspirevideo ${
                  muteEnableAutoPlay === "true" ? " muted" : ""
                } ${playsAboveFold === "true" ? "" : "lazy-video"}`}
                data-desktop-vid={`${modalVideoDesktop}`}
                data-mobile-vid={modalVideoMobile}
                data-mobile-lscape-vid={modalVideoMobileLandscape}
                onVolumeChange={() => onVolumeChange(inspireRef?.current)}
                id={"herovideo-desktop-1"}
                width="100%"
                playsInline
                preload="metadata"
                {...(controlType === "defaultControl" &&
                !(hideEnableAutoPlay === "false")
                  ? { controls: true }
                  : {})}
                muted={muteEnableAutoPlay === "true" ? true : false}
                autoPlay={muteEnableAutoPlay === "true" ? true : false}
                loop={muteEnableAutoPlay === "true" ? true : false}
                onTimeUpdate={onTimeUpdate}
              >
                <source src={modelVideo} type="video/mp4" />
                {items.map((transcript, index) => (
                  <track
                    key={`video-track-${dynamicId}-${index}`}
                    label={transcript?.languageLabel}
                    kind="subtitles"
                    srcLang={transcript?.languageCode}
                    src={transcript?.transcriptSrcPath}
                    default={items?.length ? true : false}
                  />
                ))}
              </video>
              <div
                class="vidio_popup_close_button inspired-video-popup-close"
                ref={closeBtnRef}
              >
                <button
                  role="button"
                  onClick={() =>
                    onPlayInspireVideoClose(
                      videoRef?.current,
                      inspireRef?.current,
                      closeBtnRef?.current,
                      addVideoDataLayer
                    )
                  }
                >
                  <span aria-hidden="true"></span>
                </button>
              </div>
            </div>
          ) : null}
          <div
            className="cmp-img-caption"
            data-gbh-data-layer={`{"clickInternalLinks":"true","internalLinkPosition":"${eventPageType?.toLowerCase()}:${
              componentPosition || "teaser"
            }","internalLinkZoneName":"${eventPageType?.toLowerCase()}:${
              componentZone || "teaser"
            }","internalLinkURL":"n/a","internalLinkName":"n/a","eventName":"${eventPageType?.toLowerCase()}:${
              eventName || eventActionName
            }","internalLinkType":"${eventPageType?.toLowerCase()}:${
              componentLinkType || "teaser"
            }","eventType":"navigation","eventAction":"${eventPageType?.toLowerCase()}:${
              eventAction || eventActionName
            }"}`}
          >
            {sanitizeInnerHtml(caption)}
          </div>
        </div>

        <div className="cmp-teaser__content">
          {pretitle ? (
            <div className="cmp-teaser__pretitle">
              {sanitizeInnerHtml(pretitle)}
            </div>
          ) : null}
          {title ? (
            <div className="cmp-teaser__title">{sanitizeInnerHtml(title)}</div>
          ) : null}
          {description ? (
            <Description
              general={general}
              linkURL={linkCTA}
              linkURLRef={linkURLRef}
              linkNewTab={linkNewTab}
              description={description}
              mainTitle={mainTitle}
              componentPosition={componentPosition}
              componentLinkType={componentLinkType}
              pageType={pageType}
              linkDescription={linkDescription}
            />
          ) : null}
          {videoActionsEnabled === "true" || enablePopUPVideo === "true" ? (
            <div className="cmp-teaser__action-container">
              {actionsEnabled ? (
                <div class="cta_link">
                  {actions.map((action, index) => {
                    const {
                      playVideoCTA = false,
                      primaryCTA = false,
                      secondaryCTA = false,
                      url = "",
                      title = "",
                      newTab = "",
                      urlRef = "",
                    } = action
                    return (
                      <Action
                        key={`video-action-${index + 1}-${getRandom()}`}
                        primaryCTA={convertStringToBoolean(primaryCTA)}
                        secondaryCTA={convertStringToBoolean(secondaryCTA)}
                        playVideoCTA={convertStringToBoolean(playVideoCTA)}
                        link={url}
                        text={title}
                        newTab={convertStringToBoolean(newTab)}
                        urlRef={urlRef}
                        id={dynamicId}
                        pageType={pageType}
                        linkAll={convertStringToBoolean(linkAll)}
                        mainTitle={mainTitle}
                        dataLayerLinkType={dataLayerLinkType}
                        eventName={eventName}
                        eventAction={eventAction}
                        componentPosition={componentPosition}
                        propertiesPageType={propertiesPageType}
                        playVideoCTAcb={event =>
                          onPlayVideoInModal(
                            event,
                            propertiesPageType,
                            videoContainerRef?.current,
                            videoRef?.current
                          )
                        }
                      />
                    )
                  })}
                </div>
              ) : null}

              {enablePopUPVideo === "true" ? (
                <div className="play_modal_video_cta">
                  <button
                    className={`playVideoCta ${
                      textLinkCta === "true" ? "textLinkCta" : ""
                    } ${
                      textLinkCtaOffWhite === "true"
                        ? "textLinkCtaoffwhite"
                        : ""
                    } ${blackBgCta === "true" ? "blackBgCta" : ""} ${
                      offWhiteBg === "true" ? "offWhiteBg" : ""
                    }`}
                    onClick={() =>
                      onPlayInspireVideo(
                        videoRef?.current,
                        inspireRef?.current,
                        closeBtnRef?.current,
                        addVideoDataLayer
                      )
                    }
                  >
                    <span>{modalCta}</span>
                  </button>
                </div>
              ) : null}
            </div>
          ) : null}
          {/* Enable favorite and share button on video */}
          {enableFav || enableShare ? (
            <div className="cmp-teaser__icons">
              {enableFav ? (
                <div
                  className={`favorite fav-video-trigger${
                    isFavourited ? " favorite-filled" : ""
                  }`}
                  tabIndex="0"
                  onClick={event =>
                    onFavVideo(
                      event,
                      videoDesktop,
                      videoDesktop,
                      alt,
                      componentLinkType || caption,
                      videoTitle ||
                        componentLinkType.toLowerCase() ||
                        "hero video",
                      "",
                      favVideoId,
                      res => {
                        setIsFavourited(res)
                      }
                    )
                  }
                />
              ) : null}
              {enableShare ? (
                <div
                  className="share share-video-trigger"
                  tabIndex="0"
                  aria-label="share"
                  data-gbh-data-layer={`{"clickInternalLinks":"true","internalLinkPosition":"${pageType?.toLowerCase()}","internalLinkZoneName":"${pageType?.toLowerCase()}:${
                    componentZone?.toLowerCase() || "teaser"
                  }","internalLinkURL":"n/a","internalLinkName":"share","eventName":"${pageType?.toLowerCase()}:share", "internalLinkType":"${pageType?.toLowerCase()}:${
                    componentLinkType?.toLowerCase() || "share"
                  }","eventType":"navigation","eventAction":"${pageType?.toLowerCase()}:share"}`}
                  data-gbh-clickable="true"
                  onClick={e =>
                    onShareVideo(e, () => setShowSocialShare(!showSocialShare))
                  }
                >
                  {showSocialShare ? (
                    <div className="share-cmp" style={{ display: "block" }}>
                      <SocialShare
                        componentProp={{ assetUrl: videoDesktop }}
                        enableDownload={false}
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </section>
  )
}

export default React.memo(Video)
