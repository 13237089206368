import React, { useEffect, useState } from "react"
import useViewport from "@/hooks/useViewport"

const Coach = props => {
  const {
    text = "no text",
    hide = false,
    showHeaderCoach = false,
    panelState,
    isOverlapping,
  } = props
  const [show, setShow] = useState(false)
  const device = useViewport()
  const [coachMarkPosition, setCoachMarkPosition] = useState({
    top: 0,
    left: 0,
    zIndex: 1,
  })
  useEffect(() => {
    updateCoachMarkPosition()
    window.addEventListener("resize", updateCoachMarkPosition)
    return () => {
      window.removeEventListener("resize", updateCoachMarkPosition)
    }
  }, [device])

  const updateCoachMarkPosition = () => {
    let top
    let left
    if (showHeaderCoach) {
      const addCoachFirstChild = document
        ?.querySelector(".add-coach")
        ?.firstElementChild?.getBoundingClientRect()?.right
      const contentChange = addCoachFirstChild - 85

      if (device?.isExtraSmallScreen) {
        top = "110px"
        left = "65px"
      } else if (device?.isSmallScreen) {
        top = "115px"
        left = `${contentChange}px`
      } else if (device?.isMediumScreen) {
        top = "110px"
        left = `${contentChange}px`
      } else {
        top = isOverlapping ? "115px" : "75px"
        left = `${contentChange}px`
      }
      setCoachMarkPosition({
        top: top,
        left: left,
      })
    }
  }
  useEffect(() => {
    if (!hide) setShow(true)
    else setShow(false)
  }, [])
  const conditionalCss = showHeaderCoach
    ? {
        top: coachMarkPosition.top,
        left: coachMarkPosition.left,
        zIndex: panelState ? 1 : 2,
      }
    : null
  return show && !hide ? (
    <div
      className="coach-mark"
      data-testid="header-back-button"
      style={conditionalCss}
    >
      <span className="coach-text">{text}</span>
      <button
        className="close-button-coach mx-2"
        onClick={() => setShow(false)}
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.52079 11.3239L0.677734 10.4808L5.15773 6.0008L0.677734 1.52079L1.52079 0.677734L6.0008 5.15773L10.4808 0.677734L11.3239 1.52079L6.84386 6.0008L11.3239 10.4808L10.4808 11.3239L6.0008 6.84386L1.52079 11.3239Z"
            fill="#17181D"
          />
        </svg>
      </button>
    </div>
  ) : null
}

export default Coach
