import React, { useEffect, useState } from "react"
import cx from "classnames"
import { withOrientationChange } from "react-device-detect"

import useWindowResize from "@/hooks/useWindowResize"
import { getShortenedUrl, sanitizeText } from "@/utils/helper"
import { getEventInfo } from "@/components/InPageSearch/v1/analytics"

import Button from "@/components/core/Button/Button"
import PartsViewer from "@/components/PartsViewer/v1/PartsViewer"
import PartsViewerV2 from "@/components/PartsViewer/v2/PartsViewer"
import CONSTANTS, { inPageSearchEndPoint } from "@/constants/index"
import inPageSearchStyle from "@/components/InPageSearch/v1/index.module.scss"
import { apim } from "@/constants/api"
import _get from "lodash/get"
import qs from "qs"

const InPageSearchView = props => {
  const {
    bgColor = "",
    loading = false,
    partId = "",
    isSkuSearch = false,
    productId = "",
    searchString = "",
    pdfPartsDiagram = "",
    ctaLink = "",
    ctaLabel = "",
    type = "",
    onChange = () => {},
    title = "",
    placeholder = "",
    lwAppName = "",
    language = "en",
    svgFileMap = []
  } = props

  const partsViewerV2Enabled = process.env.NEXT_PUBLIC_PARTSVIEWERV2_ENABLED === "enabled" || false

  const [width] = useWindowResize()
  const isDarkBg = bgColor === "bgDark"

  const [isShowModal, setIsShowModal] = useState(false)
  const [shortenedUrl, setShortenedUrl] = useState(ctaLink)

  useEffect(() => {
    if (ctaLink) {
      getShortenedUrl(ctaLink).then(url => setShortenedUrl(url))
    }
  }, [ctaLink])

  const handleKeyPress = event => {
    const { keyCode = "", key = "" } = event
    if (keyCode === CONSTANTS.KEYCODE.ENTER && key === CONSTANTS.KEY.ENTER) {
      if (searchString !== "" && searchString.length <= 3) {
        location.href = `${shortenedUrl}?keyword=${searchString}&service=true`
      } else {
        const fl =
          "id,sku_s,slug_s,ProductResource.InteractiveSVG.resourceName_ss,ProductProductNo_s,ProductResource.InteractiveSVG.resourceTitle_ss,ProductResource.PartsPDFFileName.resourceFullWebURL_ss"
        apim
          .get(inPageSearchEndPoint, {
            params: {
              q: searchString,
              fl,
              fq: ['Products:("ct")', `language_s:("${language}")`],
              collections: lwAppName.toLowerCase(),
              profilename: `profile_${lwAppName.toLowerCase()}_Search`,
            },
            paramsSerializer: params => {
              return qs.stringify(params, {
                arrayFormat: "repeat",
                encode: false,
              })
            },
          })
          .then(res => {
            const {
              data: {
                response: { numFound = "", docs = [] } = {},
                fusion: { exactMatch = "" } = {},
              } = {},
            } = res
            if (numFound === 1 && exactMatch) {
              const mResources = _get(
                docs[0],
                "ProductResource.InteractiveSVG.resourceName_ss",
                ""
              )
              const partId = Array.isArray(mResources)
                ? mResources[0]
                : mResources

              const productId = _get(docs[0], "ProductProductNo_s", "")
              setIsShowModal(Boolean(partId) && Boolean(productId))
            } else {
              location.href = `${shortenedUrl}?keyword=${searchString}&service=true`
            }
          })
          .catch(err => console.log("error occured in fetch search term", err))
      }
    }
  }

  const handleOnClick = event => {
    event?.preventDefault()

    if (Boolean(partId) && Boolean(productId)) {
      setIsShowModal(true)
    }

    if (pdfPartsDiagram && pdfPartsDiagram[0]) {
      handlePDFDownload(event, pdfPartsDiagram ? pdfPartsDiagram[0] : "")
    }
  }

  const handlePDFDownload = (e, link) => {
    e.stopPropagation()

    try {
      window.open(link.trim(), "_blank")
    } catch (err) {
      console.log(err)
    }
  }

  const onCloseModal = () => {
    setIsShowModal(false)
  }

  const getButton = () => {
    if (isSkuSearch) {
      return (
        <div className={inPageSearchStyle?.inPageSearchWrapper}>
          <button
            className={`kf-react-button button gbh-data-layer large ${
              isDarkBg ? "secondary" : "ghost"
            } ${loading ? "disabled" : " "} ${
              searchString && searchString !== null && searchString.length > 0
                ? "--active"
                : "--inactive"
            }`}
            data-gbh-data-layer={getEventInfo(
              `${ctaLink}?keyword=${searchString}`,
              "success"
            )}
            onClick={handleOnClick}
          >
            {ctaLabel}
          </button>
        </div>
      )
    } else {
      return (
        <Button
          label={ctaLabel}
          type={isDarkBg ? "secondary" : "ghost"}
          to={`${shortenedUrl}?keyword=${searchString}&service=true`}
          className={`gbh-data-layer ${loading ? "disabled" : " "} ${
            searchString && searchString !== null && searchString.length > 0
              ? "--active"
              : "--inactive"
          }`}
          data-gbh-data-layer={getEventInfo(
            `${ctaLink}?keyword=${searchString}`,
            "error",
            type,
            searchString
          )}
        />
      )
    }
  }

  return (
    <div className="in-page-search">
      <div
        className={cx(
          "inpage-search kf-react-container",
          isDarkBg && "inpage-search--dark"
        )}
      >
        <div className="container">
          <div className="row inpage-search-section">
            <div
              className={cx(
                "col-12 inpage-search__title",
                isDarkBg && "inpage-search__title--dark"
              )}
            >
              {sanitizeText(title)}
            </div>
            <div className="col-12 inpage-search__header">
              <div
                className={cx(
                  "inpage-search__header-section",
                  !isDarkBg && "inpage-search__header-section--light"
                )}
              >
                <div className="inpage-search__search-filed">
                  <label htmlFor="inpage-search__search-control">
                    <input
                      autoComplete="off"
                      type="text"
                      className={cx(
                        "inpage-search__search-control",
                        isDarkBg && "inpage-search__search-control--dark"
                      )}
                      id="inpage-search__search-control"
                      placeholder={placeholder}
                      aria-labelledby="searchBox"
                      onChange={e => onChange(e.target.value)}
                      onKeyUp={e => handleKeyPress(e)}
                    />
                  </label>
                </div>
                {width > 480 &&
                  searchString &&
                  searchString !== null &&
                  searchString.length > 0 &&
                  getButton()}
                {
                  partsViewerV2Enabled 
                  ?
                  <PartsViewerV2
                    page="search"
                    onClose={onCloseModal}
                    showModal={isShowModal}
                    productId={productId}
                    partId={partId}
                    fileMap={svgFileMap}
                  /> 
                  :
                  <PartsViewer
                    productId={productId}
                    partId={partId}
                    showModal={isShowModal}
                    page="search"
                    onClose={onCloseModal}
                  />
                }                
              </div>
              {width <= 480 && getButton()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withOrientationChange(InPageSearchView)
