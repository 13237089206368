import { getProjectionData } from "@/services/ct.service.js"
import { getConfig } from "@/constants/config"

export const getProjectionPartListData = async partList => {
  try {
    const response = await getConfig()
    const currencyCode = response?.internationalization?.currencyCode
    const currencySign = response?.internationalization?.currencySign
    const itemsCount = 20
    let startFrom = 0
    let endTo = itemsCount
    const initialList = []
    const groupArrayCount = Math.ceil(partList.length / itemsCount)
    const skus = partList.map(val => val.skuId)
    if (partList?.length <= itemsCount) {
      const resp = await getProjectionData({ skus })
      return getPartsPricebySku(resp, currencyCode, currencySign)
    } else {
      const list = []
      Array.from({ length: groupArrayCount }, () => {
        list.push([partList.slice(startFrom, endTo)])
        startFrom = endTo
        endTo = endTo + itemsCount
        return null
      })
      list.forEach(val => {
        const skus = val[0].map(v => v.skuId)
        const resp = getProjectionData({ skus })
        initialList.push(resp)
      })
      const res = await Promise.allSettled(initialList)
      const combineItem = res.flatMap(x => x.value)
      return getPartsPricebySku(combineItem, currencyCode, currencySign)
    }
  } catch (err) {
    return err
  }
}
const getPartsPricebySku = (list, currencyCode = "USD", currencySign = "$") => {
  const sortItems = i => ({
    sku: i?.sku,
    currencySign,
    prices: i?.prices
      .filter(i => i?.value?.currencyCode == currencyCode)
      .reduce((a, i) => {
        return { ...a, [i?.customerGroup?.obj?.key ?? "ANY"]: i?.value }
      }, {}),
  })
  return list.reduce((acc, sku) => {
    return {
      ...acc,
      [sku?.masterVariant?.sku]: [
        ...sku.variants.map(sortItems),
        ...[sku.masterVariant].map(sortItems),
      ],
    }
  }, {})
}
