import { useTranslation } from "next-i18next"
import { useEffect, useRef, useState } from "react"
import { useSelector, router } from "react-redux"
import Coach from "@/components/core/Coach/Coach"
import MiniCart from "@/components/Shared/MiniCart/v2/MiniCart"
import useViewport from "@/hooks/useViewport"
import { selectCartState } from "@/store/features/cartSlice"
import { getAriaLabel, getCartCount } from "@/utils/cart"
import { getProjectionPartListData } from "@/utils/ProjectionPartlist.js"
import printIcon from "@/public/scout_icon/icons/printIcon.svg"
import shareIcon from "@/public/scout_icon/icons/shareIcon.svg"

const FileSelection = props => {
  const {
    files,
    isActive,
    setSelected,
    setPopupModal,
    title,
    sku,
    skuImage,
    getFileTitle,
    setParts,
    panelState,
    initialIndex,
    setPartsHeight,
  } = props
  const [visibleCoach, setVisibleCoach] = useState(false)
  const device = useViewport()
  const divRef = useRef(null)
  const tabRefs = useRef([])
  const { t } = useTranslation("common")
  const cartCount = getCartCount()
  const ariaLabel = getAriaLabel(t, cartCount)
  const { cartUrl } = useSelector(selectCartState)
  const [isOverlapping, setIsOverlapping] = useState(false)
  const [features, setFeatures] = useState({
    displayClass: "d-inline",
    floating:
      device?.isSmallScreen || device?.isMediumScreen ? "" : "float-right",
    heightof: "h-80",
    skuDisplayClass:
      device?.isSmallScreen || device?.isMediumScreen ? "" : "d-inline",
  })
  const redirect = () => {
    router.push(`${cartUrl}`)
  }

  const eventHandler = {
    store: {
      cartCount,
      ariaLabel,
      cartUrl,
    },
    callbacks: {
      onClick: () => {
        redirect()
      },
    },
  }

  const scrollVisible = index => {
    if (tabRefs?.current[index]) {
      tabRefs?.current[index]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      })
    }
  }

  useEffect(() => {
    if (tabRefs?.current[initialIndex]) {
      requestAnimationFrame(() =>
        tabRefs?.current[initialIndex]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        })
      )
    }
  }, [files, initialIndex])
  useEffect(() => {
    setPartsHeight(features?.heightof)
    const timer = setTimeout(() => setVisibleCoach(true), 3000)
    return () => clearTimeout(timer)
  }, [])

  const screenWidth =
    device.isLargeScreen || device.isExtraLargeScreen || device.isMediumScreen

  const handlerFileSelection = async (e, item, index) => {
    if (!item?.prices && item?.Parts?.length !== 0) {
      const prices = await getProjectionPartListData(item?.Parts)
      setParts(prevState => {
        const {
          partsInfo: { SVGFiles },
        } = prevState
        const updatePrice = SVGFiles?.filter(
          val => val.FileName === item.FileName
        )
        updatePrice[0].prices = prices
        return { ...prevState }
      })
    }
    setSelected(item)
    scrollVisible(index)
    setPopupModal(false)
  }
  useEffect(() => {
    const checkOverlap = () => {
      if (device?.isLargeTabView) {
        let addCoachSpan = 0
        const childDiv = Array.from(
          document?.querySelector(".add-coach")?.children
        )
        childDiv.map(
          child => (addCoachSpan += child?.getBoundingClientRect()?.width)
        )
        const overlap =
          document?.querySelector(".modal_header")?.getBoundingClientRect()
            ?.width +
            addCoachSpan +
            100 >
          document.querySelector(".section-title-file")?.getBoundingClientRect()
            ?.width
        setIsOverlapping(overlap)
      }
    }
    checkOverlap()
    window.addEventListener("resize", checkOverlap)
    return () => window.removeEventListener("resize", checkOverlap)
  }, [])

  useEffect(() => {
    if (isOverlapping) {
      setPartsHeight("h-115")
      setFeatures(prevfeatures => ({
        ...prevfeatures,
        displayClass: "d-inline",
        floating: "",
        heightof: "h-115",
        marginTb: "my-3",
        skuDisplayClass: "",
      }))
    }
  }, [isOverlapping])

  return (
    <>
      <div className={`check-modal d-flex flex-grow-1 ${features.heightof}`}>
        <div className="flex-container modal_panel selection">
          <div className={`only-image image-container`}>
            <img className={`image-part`} src={skuImage} alt="skuimage" />
          </div>

          <div className={`section-title-file ${features.displayClass} w-100`}>
            <div className={`modal_header flex-item ${features.displayClass}`}>
              <div className={`sku-part ${features.skuDisplayClass}`}>
                <span className="sku-title">{title}</span>
                <span className="sku">{sku}</span>
              </div>
            </div>

            <div
              className={`flex-container header-content ${features.floating}`}
            >
              {visibleCoach ? (
                <Coach
                  text=""
                  show={true}
                  showHeaderCoach={true}
                  panelState={panelState}
                  isOverlapping={isOverlapping}
                />
              ) : null}
              <div ref={divRef} className="add-coach">
                {files
                  ?.filter(files => "FileName" in files)
                  ?.map((item, index) => {
                    return (
                      <span
                        key={`${index + item?.FileName}`}
                        ref={el => (tabRefs.current[index] = el)}
                        className={
                          "file-tab user-select-none  header-icons header-name" +
                          (isActive(item) ? " activeTab" : "")
                        }
                        onClick={e => {
                          handlerFileSelection(e, item, index)
                        }}
                        onKeyDown={val => val}
                      >
                        {getFileTitle(item?.FileName)}
                      </span>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            !(device.isMediumScreen || device.isSmallScreen) ? "mr-50" : ""
          } ml-20`}
        >
          {screenWidth ? (
            <div className="print-share-div d-flex">
              <div className="header-img d-flex">
                <img src={printIcon?.src} alt={"print"} />
                <img src={shareIcon?.src} alt={"share"} />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="parts-cart-wrapper">
          <MiniCart eventHandler={eventHandler} source={"from desk"} />
        </div>
      </div>
    </>
  )
}

export default FileSelection
