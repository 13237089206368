import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { validateField } from "@/utils/helper"
import { callNotification } from "@/components/ProductDetail/v3/AddToCart/utils"
import Input from "@/components/core/Input/Input"

const NotifyItems = ({ item }) => {
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState({ show: false, message: "" })
  const [showWelcome, setShowWelcome] = useState(false)
  const [enableNotify, setEnableNotify] = useState(false)

  useEffect(() => {
    setShowWelcome(false)
    setEmailError({ show: false, message: "" })
    setEmail("")
  }, [item])

  const handleEmailChange = async val => {
    const inputValue = val.trim()
    setEmailError({ show: false, message: "" })
    setEmail(inputValue)
    if (!(await validateField("email", inputValue))) {
      setEnableNotify(false)
    } else {
      setEnableNotify(true)
    }
  }

  const callBackNotify = isSuccess => {
    if (isSuccess) {
      setShowWelcome(true)
      setEmail("")
      setEnableNotify(false)
    } else {
      setEmailError({
        show: true,
        message: "Notification has been sent already for this email address",
      })
    }
  }

  const handleNotification = () => {
    const sku = item?.currentVarent?.id
    if (sku) callNotification({ email, sku, callBackNotify })
  }

  return (
    <>
      <Row>
        <Col className="notify-contianer">
          <p className={`fs-16 fw-500 notify-heading `}>
            {showWelcome
              ? "Success!"
              : "This Item is currently Out of Stock on Kohler.com"}
          </p>

          <p className={`fs-14 fw-400 notify-details `}>
            {showWelcome
              ? "Thank you! We will notify you as soon as the item requested is back in stock."
              : "Enter your email to be notified when this item is back in stock online or check availability at a local store or showroom."}
          </p>
          {!showWelcome ? (
            <>
              <div className="email-inputbox">
                <Input
                  id="email-notify"
                  onChange={e => handleEmailChange(e.target.value)}
                  type="email"
                  label="Email Address"
                  ariaRequried={true}
                  maxLength={60}
                  value={email}
                  showError={emailError.show}
                  errorMessage={emailError.message}
                />
              </div>
              <button
                className={`notify-button ${
                  enableNotify ? "" : "disabled-btn"
                }`}
                onClick={() => (enableNotify ? handleNotification() : "")}
                disabled={!enableNotify}
              >
                Notify me
              </button>
            </>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </>
  )
}

export default NotifyItems
